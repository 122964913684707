import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToRem,
} from '@vfit/shared/themes';
import styled from 'styled-components';

export const AddressManualContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const AddressManualTitle = styled.h3`
  margin: 0;
  ${pxToCssFontSize(30)}
  font-family: ${fonts.exbold};
  font-weight: 400;
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFontSize(42)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFontSize(36)}
  }
`;

export const AddressManualInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${pxToRem(16)};

  & div {
    padding: 0;
  }
`;

export const ErrorMsg = styled.p`
  @keyframes fadein {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  color: ${colors.$e60000};
  ${pxToCssFont(14, 18)}
  font-family: ${fonts.regular};
  text-align: left;
`;

