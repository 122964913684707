import { useEffect, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { IUseHeaderBanner } from './headerBanner.models';

export const useHeaderBanner: IUseHeaderBanner = ({
  htmlDesktop,
  htmlMobile = '',
  htmlTablet = '',
}) => {
  const { isMobile, isTablet, isBigDesktop, isDesktop } = useDeviceType();

  const getHtmlByDevice = (): string => {
    if (isDesktop || isBigDesktop) {
      return htmlDesktop || '';
    }
    if (isMobile) {
      return htmlMobile;
    }
    return htmlTablet;
  };

  const [html, setHtml] = useState(getHtmlByDevice());

  useEffect(() => {
    setHtml(getHtmlByDevice());
  }, [isMobile, isTablet, isBigDesktop, isDesktop]);

  return {
    htmlPurified: html,
  };
};
