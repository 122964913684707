import { ILinkWithIconProps } from './linkWithIcon.models';

import { StyledDiv, StyledAnchor, StyledLinkText, StyledIconContainer } from './linkWithIcon.style';

/** LINKWITHICON DOCS
 *
 * @description A styled React Functional Component that works as an anchor element with customizable text, href and
 * an optional icon.
 *
 * @param props:
 * -
 * - text: string -> for setting the text rendered by the element;
 * - href?: string -> for setting the href of the anchor element;
 * - size?: '1' | '2' -> for setting the font size attribute of the text;
 * - icon?: JSX.Element -> for rendering an icon along with the text;
 * - onClick?: () => void -> for passing down a function to the element;
 *
 * @returns A JSX Element.
 *
 *
 */

const LinkWithIcon: React.FC<ILinkWithIconProps> = (props) => {
  const { text, href, icon, size, disabled, onClick, color, variant } = props;

  return (
    <StyledDiv size={size} className="linkWithIcon">
      <StyledAnchor href={href} onClick={onClick} disabled={disabled || false}>
        <StyledLinkText color={color || ''} variant={variant}>
          {text} <StyledIconContainer variant={variant} >{icon}</StyledIconContainer>{' '}
        </StyledLinkText>
      </StyledAnchor>
    </StyledDiv>
  );
};

export default LinkWithIcon;
