import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  display: flex;
  align-content: center;
  width: ${pxToRem(327)};
  border-radius: ${pxToRem(20)};
  border: 1px solid #bebebe;
  margin: 0 auto;

  @media (min-width: ${breakpoints.tablet}) {
    justify-content: space-between;
    width: 100%;
    max-width: ${pxToRem(1280)};
  }
`;

export const DescriptionCard = styled.div`
  padding: ${pxToRem(32, 0, 24, 24)};
  @media (min-width: ${breakpoints.tablet}) {
    padding: ${pxToRem(40, 0, 24, 24)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 55%;
    padding: ${pxToRem(64, 0, 80, 80)};
  }
`;

export const QRCodeCard = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  border-top-right-radius: ${pxToRem(20)};
  border-bottom-right-radius: ${pxToRem(20)};
  background: rgb(242, 242, 242);
  width: ${pxToRem(405)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
  }
`;

export const QRCode = styled.div`
  margin: auto;
  width: 100%;
  height: 100%;
`;

export const StoreContainer = styled.div`
  height: ${pxToRem(56.63)};
  display: flex;
  gap: 16px;
`;

export const Store = styled.div`
  width: ${pxToRem(152.5)};
  height: auto;
  align-content: center;
`;

export const ButtonContainer = styled.div`
  width: ${pxToRem(277)};
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$7e7e7e};

  img {
    width: ${pxToRem(18)};
  }
`;

export const Title = styled.div`
  ${pxToCssFont(28, 36)}
  margin-bottom: ${pxToRem(24)};
  font-weight: 400;
  font-family: ${fonts.exbold};

  p {
    margin: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 48)}
  }
`;

export const Description = styled.div`
  ${pxToCssFont(18, 24)}
  margin-bottom: ${pxToRem(24)};

  p {
    margin: 0;
  }
`;

export const MainContainer = styled.div`
  margin: 25px;
`;

