import styled from 'styled-components';
import { IBannerCMS } from '@vfit/shared/models';

export const HTMLWrapper = styled.div``;

export const BannerStyle = styled.section<{
  animationType?: IBannerCMS['animationType'];
  delay?: string;
}>`
  left: 0;
  overflow: hidden;
  top: 0;
  width: 100%;
  z-index: 1;
  ${(p) => (p.animationType === 'slide-left-to-right' ? 'transform: translateX(-100%);' : '')}
  ${(p) => (p.animationType === 'slide-down' ? 'transform: translateY(-100%);' : '')}
  opacity: ${(p) => (p.animationType === 'fade-in' ? 0 : 1)};
  animation: ${(p) => p.animationType} 0.5s ease-in forwards
    ${(p) => (p.delay ? `${p.delay}s` : '')};

  @keyframes slide-left-to-right {
    from {
      transform: translateX(-100%);
    }

    to {
      transform: translateX(0);
    }
  }

  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
      opacity: 0;
    }

    to {
      transform: translateY(0);
      opacity: 1;
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
