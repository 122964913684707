import { SVGProps, memo } from 'react';

const ArrowLeft = (props: SVGProps<SVGElement>) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M15.3549 5.02023C15.5501 4.82496 15.5501 4.50838 15.3549 4.31312C15.1596 4.11786 14.843 4.11786 14.6477 4.31312L15.3549 5.02023ZM3.66797 16L3.31442 15.6465L2.96086 16L3.31442 16.3536L3.66797 16ZM14.6477 27.6869C14.843 27.8822 15.1596 27.8822 15.3549 27.6869C15.5501 27.4916 15.5501 27.175 15.3549 26.9798L14.6477 27.6869ZM28.668 16.5C28.9441 16.5 29.168 16.2761 29.168 16C29.168 15.7239 28.9441 15.5 28.668 15.5V16.5ZM14.6477 4.31312L3.31442 15.6465L4.02152 16.3536L15.3549 5.02023L14.6477 4.31312ZM3.31442 16.3536L14.6477 27.6869L15.3549 26.9798L4.02152 15.6465L3.31442 16.3536ZM3.66797 16.5H28.668V15.5H3.66797V16.5Z"
        fill="#0d0d0d"
      />
    </svg>
  );
};

export default memo<SVGProps<SVGSVGElement>>(ArrowLeft);
