import { CustomText } from '@vfit/shared/atoms';
import { Container, Title } from './consentCard.style';
import CheckboxList from '../CheckboxList/checkboxList';
import { IOptionalText } from '../OptionalText/optionalText.models';
import { IConsentCard } from './consentCard.models';

const ConsentCard = (props: IConsentCard) => {
  const { consent, onChangeConsent } = props;

  const options: IOptionalText[] = [
    { title: 'Autorizzo', value: 'true' },
    { title: 'Non autorizzo', value: 'false' },
  ];

  const handeChangeItem = (t: IOptionalText) => {
    onChangeConsent(consent, t.value === 'true');
  };

  return (
    <Container>
      <Title>{consent.name}</Title>
      <CustomText
        text={consent.longDescription || ''}
        modal
        sizeMobile={16}
        lineHeightMobile={22}
        size={18}
        lineHeight={24}
        textAlign="start"
      />
      <CheckboxList
        passedValueIndex={consent.selectedValue ? 0 : 1}
        items={options}
        onChangeItem={handeChangeItem}
        groupName={`group${consent.id}`}
      />
    </Container>
  );
};

export default ConsentCard;
