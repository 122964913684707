import {ButtonSlide, Price} from "@vfit/shared/atoms";
import {checkWindow, openPopup, trackLink} from "@vfit/shared/data-access";
import {purify} from "@vfit/shared/themes";
import React, {Children, useEffect, useRef, useState} from "react";
import {useIntersectionObserver} from "@vfit/shared/hooks";
import {CardPriceLayout, FooterPrice, Specs, StickyButton} from "../headerWithMap.style";
import {ICardPriceProps} from "../headerWithMap.models";
import {CheckMark} from "./checkMark";

export const CardPrice = ({offerName, offerDescription, recurrence, price, ctaLabel, originalPrice, redirectUrl, isMobile, specs, userType, urlClickToCall} : ICardPriceProps) => {
  const buttonRef = useRef(null);
  const { isIntersecting } =
  useIntersectionObserver(buttonRef, { rootMargin: '-10%' }) || {};
  const [isScroll, setIsScroll] = useState(false);
  const isPanicModeEnabled = checkWindow() && (window as any).isPanicMode;
  const handleClick = () => {
    trackLink(`click on ${ctaLabel}`);
    const placeId = localStorage.getItem("placeId");
    if(userType === "legacy" || isPanicModeEnabled){
      openPopup(urlClickToCall)
    } else if (userType !== "legacy" && (!placeId || placeId === "undefined")){
        window.location.href = localStorage.getItem("cookieUrlBackup") || ""
      } else {
        window.location.href = redirectUrl;
        localStorage.setItem("cookieUrlBackup", redirectUrl);
      }
  }
  const handleScroll = () => {
      setIsScroll(window.scrollY >10);
  }
  useEffect(()=>{
    window.addEventListener("scroll",handleScroll);
    return(()=> window.removeEventListener("scroll", handleScroll))
  },[])

  const purifyListElement = (element: string) => purify([element])[0]

  return (
    <>
    <CardPriceLayout>
      <Price
        amount={price}
        oldAmount={originalPrice}
        recurrence={recurrence}
        label={offerName}
        description={offerDescription}
      />

      <ButtonSlide
        ref={buttonRef}
        label={ctaLabel}
        onClick={handleClick}
        buttonColor="#e60000"
        labelColor="#fff"
        borderColor="#e60000"
        hoverColor="#bd0000"
        clickColor="#a10000"
      />


      {isMobile &&
        <Specs>
          {specs && specs.length > 0 && Children.toArray(
            // eslint-disable-next-line react/no-danger
            specs.map(spec => spec.value ?( <li><CheckMark/><span dangerouslySetInnerHTML={{__html: purifyListElement(spec.value) || ''}}/></li>) : null)
          )}
        </Specs>}
    </CardPriceLayout>
      {isMobile && !isScroll && !isIntersecting &&
        <StickyButton id="sticky-button">
          <FooterPrice>{price}/{recurrence}</FooterPrice>
          <ButtonSlide
            label={ctaLabel}
            onClick={handleClick}
            buttonColor="#e60000"
            labelColor="#fff"
            borderColor="#e60000"
            hoverColor="#bd0000"
            clickColor="#a10000"
          />
        </StickyButton>
      }
    </>

  )
}
