import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  margin-top: 48px;

  @media (min-width: ${breakpoints.desktop}) {
    display: flex;
    justify-content: center;
    text-align: center;

    div {
      text-align: center;
      min-width: 319px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    justify-content: center;
    text-align: center;

    div {
      text-align: center;
      min-width: 319px;
    }
  }
`;

export const ButtonContainer = styled.div<{ isApp?: boolean }>`
  div {
    span {
      display: ${({ isApp }) => (isApp ? 'inline-block' : 'inherit')};
      text-transform: ${({ isApp }) => (isApp ? 'lowercase' : 'inherit')};
      font-weight: ${({ isApp }) => (isApp ? '400' : 'inherit')};
      font-size: ${({ isApp }) => (isApp ? '16px' : 'inherit')};
    }
    span::first-letter {
      text-transform: uppercase;
    }
  }
`;

export const Title = styled.p`
  margin: 0;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  color: ${colors.$262626};
  margin-bottom: 20px;
`;
