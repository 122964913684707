import { useEffect, useState } from 'react';
import { CustomText, ImageAtoms } from '@vfit/shared/atoms';
import { purify } from '@vfit/shared/themes';
import { IOptionalText } from './optionalText.models';
import {
  Title,
  Container,
  Description,
  Extra,
  ContainerExtra,
  Detail,
  TitleContainer,
} from './optionalText.style';

const OptionalText = (props: IOptionalText) => {
  const { detail, title, description, extra, titleIcon} = props;
  const [ pDescription ] = purify([ description ])
  const [type, setType] = useState<number>(0);

  useEffect(() => {
    if (detail) setType(3);
    else if (extra) setType(2);
    else if (description) setType(1);
    else setType(0);
  }, [props, description, detail, extra]);

  return (
    <Container>
      <Detail>{detail}</Detail>
      <TitleContainer>
        <Title className="title" type={type}>
          {title}
        </Title>
        {titleIcon && <ImageAtoms width='24px' nameCard='icon' image={titleIcon} />}
      </TitleContainer>
      {description && (
        <Description type={type} dangerouslySetInnerHTML={{ __html: pDescription || '' }} />
      )}
      {extra && extra.text && (
        <ContainerExtra color={extra?.color} onClick={extra?.action} className={extra?.extraClass}>
          {extra?.icon ? extra.icon : ''}
          <Extra variant={extra.variant} color={extra?.color}>
            <CustomText text={extra?.text || ''} />
          </Extra>
        </ContainerExtra>
      )}
    </Container>
  );
};

export default OptionalText;

