import { useEffect, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { IUseFloatingBanner } from './floatingbanner.models';

export const useFloatingBanner: IUseFloatingBanner = ({
  htmlDesktop,
  htmlMobile = '',
  htmlTablet = '',
}) => {
  const { isMobile, isTablet, isBigDesktop, isDesktop } = useDeviceType();

  const getHtmlByDevice = (): string => {
    if (isDesktop || isBigDesktop) {
      return htmlDesktop;
    }
    if (isMobile) {
      return htmlMobile;
    }
    return htmlTablet;
  };

  const [html, setHtml] = useState(getHtmlByDevice());

  useEffect(() => {
    setHtml(getHtmlByDevice());
  }, [isMobile, isTablet, isBigDesktop, isDesktop]);

  const [isBannerClosed, setIsBannerClosed] = useState(false);
  const closeBanner = () => setIsBannerClosed(true);

  return {
    closeBanner,
    htmlPurified: html,
    isBannerClosed,
  };
};
