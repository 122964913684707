import { ContainerTopProduct, ImageDivider, CustomText } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { purify } from '@vfit/shared/themes';
import React from 'react';
import { IFreeHtml } from './freeHtml.models';
import { DisclaimerContainer } from './freeHtml.style';

const FreeHtml = ({ html, divider, disclaimer, title }: IFreeHtml) => {
  const [htmlPurified] = purify([html]);
  return (
    <>
      {title && (
        <CustomText text={title} size={36} lineHeight={50} sizeMobile={30} lineHeightMobile={38} />
      )}
      <ContainerTopProduct
        haveTitle={!!title}
        dangerouslySetInnerHTML={{ __html: htmlPurified || '' }}
      />
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </>
  );
};

export default FreeHtml;
