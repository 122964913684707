import { ICommonSlides, IOnTrack } from '@vfit/shared/models';

export enum ScrollDirection {
  UP = 'up',
  DOWN = 'down',
}

export interface ISection {
  index: number;
  ref: HTMLDivElement | null;
}

export interface ITopHomeProps {
  slides: ICommonSlides[];
  onTrack?: IOnTrack;
}
