import { createGlobalStyle } from 'styled-components';

export const OverlayGlobalStyle = createGlobalStyle`
  body {
    overflow: hidden;
  }

  #sticky-offer-summary {
    visibility: hidden;
  }
`;

