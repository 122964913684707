import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';
import { ICSSProps } from './checkboxList.models';

export const Container = styled.div<ICSSProps & { disabled?: boolean }>`
  margin: 0 auto;
  border-radius: 6px;
  padding: 15px 17px;
  display: flex;
  flex-direction: row;
  cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
  background-color: ${(props) => (props.disabled ? '#ccc' : '')};
  margin-bottom: ${(props) =>
    props.marginBottom?.mobile ? `${props.marginBottom.mobile}` : '16'}px;
  ${(props) =>
    props.index === props.colorIndex
      ? `border: 2px solid ${colors.$00697c};`
      : `border: 1px solid ${colors.$bebebe};`}

  @media (min-width: ${breakpoints.tablet}) {
    padding: 19px 17px;
    margin-bottom: ${(props) =>
      props.marginBottom?.tablet ? `${props.marginBottom.tablet}` : '12'}px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${(props) =>
      props.marginBottom?.desktop ? `${props.marginBottom.desktop}` : '22'}px;
  }
`;

export const CheckboxContainer = styled.div<ICSSProps>`
  display: flex;
  align-items: center;
  z-index: 3;
  ${(props) => `align-self: ${props.align};`}
  flex-shrink: 0;
`;

export const Text = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  font-style: normal;
  text-align: left;
  ${pxToCssFont(20, 26)}
  width: 208px;
  margin-left: 18px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    width: 294px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 334px;
  }
`;
