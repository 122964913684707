import { IBanner } from '@vfit/shared/models';
import { useEffect, useState } from 'react';
import * as S from './headerBanner.style';
import { useHeaderBanner } from './headerBanner.hook';
import HeaderCountdown from './components/headerCountdown';

const HeaderBanner = ({
  animationType,
  animationDelay,
  htmlMobile,
  htmlTablet,
  htmlDesktop,
  enableCountdown,
  countdownExpiration,
}: IBanner) => {
  // <span id='data-days'></span> giorni,
  // <span id='data-hours'></span> ore,
  // <span id="data-minutes"></span> minuti
  // <span id="data-seconds"></span> secondi
  const [isLoading, setIsLoading] = useState(true);
  const [isShowCountdown, setIsShowCountdown] = useState(false);
  const isEnabledCountdown = enableCountdown === 'true';

  const { htmlPurified } = useHeaderBanner({
    htmlDesktop: htmlDesktop || '',
    htmlMobile: htmlMobile || '',
    htmlTablet: htmlTablet || '',
  });

  useEffect(() => {
    if (!isLoading) {
      setTimeout(() => {
        setIsShowCountdown(isEnabledCountdown && !!countdownExpiration);
      }, 200);
    }
  }, [isLoading]);

  useEffect(() => {
    if (animationDelay) {
      setTimeout(() => {
        setIsLoading(false);
      }, parseInt(animationDelay, 10) * 1000);
    }
  }, []);

  if (isLoading) return <div />;

  return (
    <S.BannerStyle animationType={animationType} delay="0">
      <S.HTMLWrapper dangerouslySetInnerHTML={{ __html: htmlPurified }} />
      {isShowCountdown && <HeaderCountdown date={countdownExpiration} />}
    </S.BannerStyle>
  );
};

export default HeaderBanner;
