import { useMutation, useQueryClient } from 'react-query';
import { errorMock } from '@vfit/consumer/data-access';
import { API } from '@vfit/shared/data-access';
import { nextClient, LoggerInstance, INextError, CustomOptions } from '@vfit/shared/data-access';
import {
  IModifyValidAddress,
  IValidateAddressRequest,
  IValidateAddressResponse,
} from '@vfit/shared/models';
import { datadogRum } from '@datadog/browser-rum';

/**
 * This method is used to validate the address from the Google Autocomplete
 * @param payload
 * @param customOptions
 * @returns
 */
const validateAddressService = (
  payload: IValidateAddressRequest,
  customOptions?: CustomOptions
): Promise<IValidateAddressResponse> =>
  nextClient.post(API.VALIDATE_ADDRESS, payload, {
    ...(customOptions?.headers && { headers: customOptions.headers }),
    ...(customOptions?.silentLoginHeaders && {
      silentLoginHeaders: customOptions.silentLoginHeaders,
    }),
  });

/**
 * validateAddressMutation used to save the Google Autocomplete Service.
 * It sets the query 'coverageToolInputAddress' used to trigger the coverage tool flow.
 * @returns
 */
export const useValidateAddressMutation = () => {
  const queryClient = useQueryClient();

  return useMutation('setAddress', (placeObject: any) =>
    queryClient.setQueryData('coverageToolInputAddress', placeObject)
  );
};

/**
 * Custom mutation hook for run validateAddressService once the useQuery coverageToolInputAddress for data storage has been setup
 * Call this after validateAddressMutation has been called
 * @returns
 */
export const useValidateAddress = (customOptions?: CustomOptions) => {
  const queryClient = useQueryClient();
  let keyString = 'validatedAddress';

  return useMutation(
    'addressValidation',
    (req: IModifyValidAddress) => {
      LoggerInstance.debug('useValidateAddress onMutateData : ', req.placeObject);
      const {
        street,
        streetNumber,
        city,
        stateOrProvince,
        postalCode,
        displayStateOrProvince,
        latitude,
        longitude,
      } = req.placeObject;
      const newFormattedData = {
        street,
        streetNumber,
        city,
        stateOrProvince,
        postalCode,
        displayStateOrProvince,
        latitude,
        longitude,
      };
      LoggerInstance.debug('useValidateAddress riformatted : ', newFormattedData);
      const payload: IValidateAddressRequest = {
        ...newFormattedData,
        displayStateOrProvince: req?.placeObject.stateOrProvince,
      };
      return validateAddressService(payload, customOptions);
    },
    {
      onMutate: async (data) => {
        if (data?.isBilling === true) {
          keyString = 'billingAddress';
        } else if (data?.isDelivery === true) {
          keyString = 'deliveryAddress';
        } else if (data?.isPickup === true) {
          keyString = 'pickupAddress';
        }
      },
      onSuccess: (data: IValidateAddressResponse, variables) => {
        LoggerInstance.debug('SUCCESS ON VALIDATE ADDRESS', data);
        const validErrorCode = ['0', '1'];
        if (data?.validAddressList && validErrorCode.includes(data?.errorCode)) {
          LoggerInstance.debug('useValidateAddress keyString : ', keyString);
          LoggerInstance.debug('useValidateAddress data : ', data);
          localStorage.setItem(keyString, JSON.stringify(data));
          queryClient.setQueryData(keyString, data);
        } else {
          LoggerInstance.debug(
            `ERROR on validateAddress: The address returned errorCode = ${data?.errorCode}`
          );
          LoggerInstance.debug('data error on validatedAddress:', data);
          LoggerInstance.debug('variables in validatedAddress:', variables);
          LoggerInstance.debug('Coverage tool step:', variables?.step);
          datadogRum.addError({
            inputVariables: variables?.placeObject,
            step: variables?.step || 'NO_STEP',
            address: data?.validAddressList?.[0] || 'NO_ADDRESS_LIST',
            error_code: data?.errorCode || 'NO_ERROR_CODE',
          });
          queryClient.setQueryData(
            keyString,
            errorMock(
              'validateAddress',
              {
                status: '200',
                statusText: `ERROR on ${keyString}`,
                url: API.VALIDATE_ADDRESS,
              },
              `L'indirizzo inserito non è valido (Code: ${data.errorCode})`
            )
          );
          localStorage.setItem(
            keyString,
            JSON.stringify(
              errorMock(
                'addressValidation',
                {
                  status: '200',
                  statusText: `ERROR on ${keyString}`,
                  url: API.VALIDATE_ADDRESS,
                },
                `L'indirizzo inserito non è valido (Code: ${data.errorCode})`
              )
            )
          );
        }
        customOptions?.onSuccess?.(data);
      },
      onError: (error: INextError) => {
        LoggerInstance.error(`ERROR - ${keyString}: `, error);
        queryClient.setQueryData(keyString, errorMock(`${keyString}`, error));
        localStorage.setItem(`${keyString}`, JSON.stringify(errorMock(`${keyString}`, error)));
        customOptions?.onError?.(error);
      },
    }
  );
};

