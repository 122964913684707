import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  margin: 0 auto;
  font-style: normal;
  color: ${colors.$262626};
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(30, 38)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
    margin-top: -12px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-top: 0;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  margin-bottom: ${pxToRem(40)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${pxToRem(26.77)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: ${pxToRem(40)};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  div {
    margin-top: ${pxToRem(37)};
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: ${pxToRem(52)};
      width: ${pxToRem(258)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: ${pxToRem(296)};
    }
  }
`;

