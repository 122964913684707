import { useDeviceType } from '@vfit/shared/hooks';
import { ITopHomeProps } from './heroSlider.models';
import HeroSliderDefault from './HeroSliderDefault/heroSliderDefault';
import HeroSliderAnimation from './HeroSliderAnimation/heroSliderAnimation';

const HeroSlider = ({ slides, onTrack }: ITopHomeProps) => {
  const { isMobile } = useDeviceType();
  if (!slides || slides.length === 0) return null;
  const originalSlides = JSON.parse(JSON.stringify(slides || []));
  return (
    <>
      {isMobile && <HeroSliderDefault slides={originalSlides} onTrack={onTrack} />}
      {!isMobile && <HeroSliderAnimation slides={originalSlides?.reverse()} onTrack={onTrack} />}
    </>
  );
};

export default HeroSlider;
