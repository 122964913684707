import React from 'react';
import { ButtonSlide, ImageDivider, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { purifyTextWithLogo, requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { ISplittedImageTextCard } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import {
  MainContainer,
  CardContainer,
  Info,
  DescriptionBox,
  Title,
  Description,
  CtaContainer,
  Container,
  ContainerImage,
  ImageElement,
} from './splittedImageTextCard.style';

const SplittedImageTextCard: React.FC<ISplittedImageTextCard> = ({
  topText,
  title,
  description,
  urlImg,
  // urlImgMobile,
  action,
  info,
  divider,
  handleButtonClick,
  disableCoverImg,
}) => {
  const { isMobile } = useDeviceType();
  const [pTitle, pDescription] = purify([title, description], purifyTextWithLogo);
  const { src, srcSet } = requireStaticImageAtomComponent(urlImg || '');

  return (
    <Container data-name='splitted-image-text-card-container'>
      {topText && <CustomText text={topText || ''} />}
      <MainContainer>
        <CardContainer>
          <DescriptionBox>
            {pTitle && <Title dangerouslySetInnerHTML={{ __html: pTitle }} />}
            {pDescription && <Description dangerouslySetInnerHTML={{ __html: pDescription }} />}
            {action?.title && (
              <CtaContainer>
                <ButtonSlide
                  borderColor="#e60000"
                  labelColor="#fff"
                  buttonColor="#e60000"
                  label={action.title}
                  onClick={() => handleButtonClick && handleButtonClick()}
                  small={isMobile}
                  smallSize={35}
                  iconColor="#fff"
                  arrowWidth={4}
                  arrowHeigth={8}
                  name={`action_splitted_${action?.type?.toString() || ''}`}
                />
              </CtaContainer>
            )}
          </DescriptionBox>
          <ContainerImage style={{ display: 'flex' }} disableCoverImg={disableCoverImg}>
            <ImageElement style={{ objectFit: 'cover' }} alt="cta" src={src} srcSet={srcSet} />
          </ContainerImage>
        </CardContainer>
        <Info>{info && <CustomText text={info} />}</Info>
      </MainContainer>
      {divider?.image && (
        <ImageDivider
          image={divider.image}
          imageMobile={divider.imageMobile}
          isMobile={isMobile}
          alt={divider.text || ''}
        />
      )}
    </Container>
  );
};

export default SplittedImageTextCard;

