import { Children, useEffect, useRef } from 'react';
import { useDeviceType, useIntersectionObserver } from '@vfit/shared/hooks';
import { Fade } from 'react-awesome-reveal';
import {
  requireVideo,
  getButtonActionByActionType,
  getVariantByActionStyle,
} from '@vfit/shared/data-access';
import { Button, ImageAtoms } from '@vfit/shared/atoms';
import { IGradientImage } from '@vfit/shared/models';
import { useRouter } from 'next/router';
import { purify } from '@vfit/shared/themes';
import { ISlideHomeProps } from './slideHome.models';
import {
  SlideHomeContainer,
  SlideHomeContent,
  SlideHomeContainerVideo,
  SlideHomeContentTop,
  SlideHomeText,
  SlideHomeButtonSlideContainer,
  HeaderDescription,
  Specs,
  CheckMarkContainer,
} from './slideHome.style';

const SlideHome = ({ slide, headerHeight }: ISlideHomeProps) => {
  const { push } = useRouter();
  const { isMobile } = useDeviceType();
  const { category, title, description, action, id, image, imageMobile, pills } = slide;
  const slideHomeRef = useRef<HTMLDivElement | null>(null);
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const entry = useIntersectionObserver(slideHomeRef, {});
  const isVisible = !!entry?.isIntersecting;
  const purifyListElement = (element: string) => purify([element])[0];

  useEffect(() => {
    if (videoRef?.current) {
      if (isVisible) videoRef?.current?.play();
      // remove pause
      // else videoRef.current?.pause();
      else videoRef?.current?.play();
    }
  }, [isVisible]);

  const content = () => (
    <SlideHomeContent headerHeight={headerHeight}>
      <SlideHomeContentTop>
        <Fade triggerOnce>
          <SlideHomeText>
            {category && <h2>{category}</h2>}
            {title && <h3>{title}</h3>}
            {description && (
              <HeaderDescription>
                <div dangerouslySetInnerHTML={{ __html: description }} />
                {pills?.pills && pills.pills?.length > 0 && (
                  <Specs>
                    {pills?.pills &&
                      Children.toArray(
                        pills.pills.map((spec) =>
                          spec.keyword ? (
                            <li>
                              <CheckMarkContainer>
                                <svg
                                  width="17"
                                  height="12"
                                  viewBox="0 0 17 12"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M1.64096 5.32276C1.26376 4.9513 0.657468 4.95359 0.282556 5.3285C-0.0917829 5.70284 -0.0946491 6.30912 0.276815 6.6869L5.30744 11.7175C5.4883 11.8984 5.73344 12 5.98951 12C6.24557 12 6.49073 11.8984 6.67157 11.7175L16.7327 1.65583C16.9175 1.47555 17.0226 1.22926 17.0243 0.970886C17.0261 0.713101 16.9244 0.465076 16.7419 0.2825C16.5593 0.0999234 16.3113 -0.00169817 16.0529 2.14747e-05C15.7951 0.00174391 15.5482 0.106811 15.3685 0.291686L5.98957 9.67022L1.64096 5.32276Z"
                                    fill="#ffffff"
                                  />
                                </svg>
                              </CheckMarkContainer>
                              <span
                                dangerouslySetInnerHTML={{
                                  __html: purifyListElement(spec.keyword) || '',
                                }}
                              />
                            </li>
                          ) : null
                        )
                      )}
                  </Specs>
                )}
              </HeaderDescription>
            )}
            {action?.title && (
              <SlideHomeButtonSlideContainer>
                <Button
                  name={`action_heroSlider_${action?.type?.toString() || ''}`}
                  onClick={() => getButtonActionByActionType(action, push)}
                  variant={getVariantByActionStyle(action.style)}
                >
                  {action.title}
                </Button>
              </SlideHomeButtonSlideContainer>
            )}
          </SlideHomeText>
        </Fade>
      </SlideHomeContentTop>
    </SlideHomeContent>
  );

  const containerImage = () => (
    <SlideHomeContainer /* style={slideImage} */ key={id} ref={slideHomeRef}>
      <ImageAtoms
        gradient={isMobile ? IGradientImage.GRADIENT_TOP_30 : IGradientImage.GRADIENT_LEFT}
        nameCard="slideHome"
        image={image}
        imageMobile={imageMobile}
        isMobile={isMobile}
      />
      {content()}
    </SlideHomeContainer>
  );

  const containerVideo = () => (
    <SlideHomeContainerVideo key={id} ref={slideHomeRef}>
      <div>
        <video
          autoPlay
          playsInline
          ref={videoRef}
          src={requireVideo(slide.video || '', slide.videoMobile || '', isMobile)}
          loop
          muted
        />
      </div>
      {content()}
    </SlideHomeContainerVideo>
  );

  return (
    <>
      {!slide.video && containerImage()}
      {slide.video && containerVideo()}
    </>
  );
};

export default SlideHome;
