import styled from 'styled-components';

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const ListElement = styled.div`
  display: flex;
  gap: 18px;
  align-items: center;
`;
export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 80%;
`;
export const Text = styled.p<{ color?: string }>`
  font-weight: 700;
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: 18px;
  margin: 0;

  p {
    margin: 0;
  }
`;
export const SubText = styled.p<{ color?: string }>`
  color: ${(props) => (props.color ? props.color : 'white')};
  font-size: 18px;
  margin: 0;

  p {
    margin: 0;
  }
`;

export const IconContainer = styled.div`
  width: 32px;
  height: 32px;
`;
