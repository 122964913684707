import styled from 'styled-components';
import { IChipStyle } from './chip.models';
import { pxToRem } from '@vfit/shared/themes';

export const ChipStyle = styled.span<IChipStyle>`
  background-color: ${(p) => p.BgColor || 'rgba(37, 40, 43, 1)'};
  border-radius: ${pxToRem(20)};
  color: ${(p) => p.color || ' rgba(255, 255, 255, 1)'};
  display: inline-block;
  font-size: ${pxToRem(12)};
  font-weight: 700;
  padding: ${pxToRem(4, 10)};
  ${(p) => (p.margin ? `margin: ${pxToRem(...p.margin)};` : '')}
`;
