import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';
export interface IDocumentItemWrapper {
  height?: number;
  visibility?: string;
}

export const DocumentItemWrapper = styled.div<IDocumentItemWrapper>`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 32px 24px;
  gap: 24px;
  width: 327px;
  min-height: ${({ height }) => `${height || 149}px`};
  visibility: ${({ visibility }) => visibility || 'inherit'};
  background: ${colors.$ffffff};
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  flex: none;
  order: 1;
  align-self: stretch;
  flex-grow: 0;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 16px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    align-items: center;
    padding: 0 32px;
    gap: 32px;
    width: 405px;
    margin: 0;
  }
`;
export const DocumentItemTitleWrapper = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    margin: 32px 0;
  }

  div {
    font-weight: 700;
    margin: 0;
  }
`;
export const DocumentButtonItemWrapper = styled.div`
  @media (min-width: ${breakpoints.desktop}) {
    margin-left: 32px;
  }

  svg {
    width: 3.82px;
    height: 7.64px;

    @media (min-width: ${breakpoints.desktop}) {
      width: 5.45px;
      height: 10.91px;
    }
  }
`;

