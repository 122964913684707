import { ButtonSlide, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { colors, fonts } from '@vfit/shared/themes';
import { IDocumentItem } from './documentItem.models';
import * as S from './documentItem.style';

const DocumentItem = (props: IDocumentItem) => {
  const { title, buttonAction, height, visibility } = props;
  const { isDesktop, isTablet } = useDeviceType();

  return (
    <S.DocumentItemWrapper height={height} visibility={visibility} className="document-item">
      <S.DocumentItemTitleWrapper>
        <CustomText
          text={title || ''}
          textAlign="left"
          lineHeight={isTablet ? 26 : 30}
          size={20}
          lineHeightMobile={26}
          sizeMobile={20}
          fontFamily={fonts.regular}
        />
      </S.DocumentItemTitleWrapper>
      <S.DocumentButtonItemWrapper>
        <ButtonSlide
          smallSize={!isDesktop ? 35 : 50}
          borderColor={colors.$262626}
          small
          onClick={() => buttonAction?.()}
          name="action_document"
        />
      </S.DocumentButtonItemWrapper>
    </S.DocumentItemWrapper>
  );
};
export default DocumentItem;

