import { ArrowLeft, ExitIcon } from '@vfit/shared-icons';
import { Background, Container, ModalContent, IconsContainer } from './ModalStepper.style';
import { IModalProps } from './ModalStepper.models';

const ModalStepper = (props: IModalProps) => {
  const {
    children,
    show,
    onCloseModal,
    onBack,
    isDetailsModal,
    withBorderRadius,
    height,
    showBackCta,
  } = props;

  if (!show) {
    return null;
  }

  const onClickHandler = () => {
    onCloseModal();
  };

  const goBack = () => {
    if (onBack) onBack();
  };

  return (
    <Background>
      <Container isDetailsModal={isDetailsModal} withBorderRadius={withBorderRadius || false}>
        <IconsContainer>
          <span
            role="button"
            tabIndex={0}
            className="closing-icon"
            onClick={onClickHandler}
            onKeyDown={onClickHandler}
          >
            <ExitIcon />
          </span>

          {showBackCta && (
            <span
              role="button"
              tabIndex={0}
              className="back-icon"
              onClick={goBack}
              onKeyDown={goBack}
            >
              <ArrowLeft />
            </span>
          )}
        </IconsContainer>
        <ModalContent className="modalContent" id='modalContent' height={height}>
          {children}
        </ModalContent>
      </Container>
    </Background>
  );
};

export default ModalStepper;
