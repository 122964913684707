import { IPriceAmount } from '@vfit/shared/models';
import { Button } from '../Cta';
import { Amount } from './components';
import { usePrice } from './price.hook';
import * as S from './price.style';

export const Price: React.FC<IPriceAmount> = ({
  amount,
  detail,
  isBlock,
  label,
  note,
  oldAmount,
  onClick,
  recurrence,
  freeAmountRecurrence,
  separator,
  specs,
  variant,
  color,
  description,
}) => {
  const { isOnlyAmount, pDetail, pNote, pDescription, pFreeAmountRecurrence,
    pAmount } = usePrice({
      amount,
      detail,
      label,
      note,
      specs,
      description,
      freeAmountRecurrence,
    });

  if (isOnlyAmount) {
    return (
      <Amount
        amount={pAmount}
        isBlock={isBlock}
        oldAmount={oldAmount}
        recurrence={recurrence}
        freeAmountRecurrence={pFreeAmountRecurrence}
        separator={separator}
        variant={variant}
      />
    );
  }

  return (
    <S.Section variant={variant} color={color}>
      <header>
        <S.Label variant={variant}>
          {label}
          <Amount
            amount={pAmount}
            isBlock
            oldAmount={oldAmount}
            recurrence={recurrence}
            freeAmountRecurrence={pFreeAmountRecurrence}
            separator={separator}
            variant={variant}
          />
        </S.Label>
        {specs && (
          <S.Specs variant={variant} color={color}>
            {specs}
          </S.Specs>
        )}
      </header>
      {pDetail && (
        <S.Detail
          dangerouslySetInnerHTML={{
            __html: pDetail,
          }}
          variant={variant}
        />
      )}
      {pDescription && (
        <S.Detail
          dangerouslySetInnerHTML={{
            __html: pDescription,
          }}
          variant={variant}
        />
      )}
      {pNote && (
        <S.Footer>
          <S.Note
            dangerouslySetInnerHTML={{
              __html: pNote,
            }}
            variant={variant}
          />
          {onClick && <Button model="circle" onClick={onClick} />}
        </S.Footer>
      )}
    </S.Section>
  );
};

