import styled, { css } from 'styled-components';
import { breakpoints, colors, fonts, pxToFontSize, pxToLineHeight } from '@vfit/shared/themes';
import { IStyledDivSize } from './linkWithIcon.models';

const variants = {
  sunrise: css`
    text-decoration: underline;
    text-underline-position: from-font;
  `,
  vr: css`
    text-decoration: ;
    text-underline-position: ;
  `,
};

export const StyledDiv = styled.div.attrs((props: IStyledDivSize) => ({
  size: props.size,
  fontMobile:
    props.size !== '2'
      ? {
          fontSize: pxToFontSize(14),
          lineHeight: pxToLineHeight(14, 18),
        }
      : {
          fontSize: pxToFontSize(16),
          lineHeight: pxToLineHeight(16, 22),
        },

  fontTabletDesktop:
    props.size !== '2'
      ? {
          fontSize: pxToFontSize(16),
          lineHeight: pxToLineHeight(16, 22),
        }
      : {
          fontSize: pxToFontSize(18),
          lineHeight: pxToLineHeight(18, 24),
        },
}))`
  display: flex;
  font-family: ${fonts.regular};
  ${(props) => props.fontMobile};
  justify-content: center;
  color: #007c92;

  @media (min-width: ${breakpoints.tablet}) {
    ${(props) => props.fontTabletDesktop};
  }
`;

export const StyledAnchor = styled.a<{ disabled: boolean }>`
  ${(props) => (props.disabled ? 'pointer-events: none' : '')};
  text-decoration: none;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
`;

export const StyledLinkText = styled.div<{ color?: string; variant?: string }>`
  font-weight: 700;
  ${({ variant }) => variants[variant || 'sunrise']};
  color: ${(props) => (props.color ? props.color : colors.$262626)};
  display: flex;
  align-items: center;
  margin: 0;

  &:hover {
    color: ${colors.$e60000};
  }

  &:active {
    color: ${colors.$e60000};
  }

  ${StyledAnchor}:visited & {
    color: #007c92;
  }
`;

export const StyledIconContainer = styled.div<{ variant?: string }>`
  display: flex;
  ${({ variant }) => (variant === 'vr' ? `width: 20px; height: 20px;` : '')}

  & svg {
    align-self: center;
  }
`;

