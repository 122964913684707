import { useState, useEffect, Children } from 'react';
import { RadioButtonSelected, RadioButtonUnselected } from '@vfit/shared-icons';
import { Container, CheckboxContainer } from './checkboxList.style';
import { ICheckbox } from './checkboxList.models';
import OptionalText from '../OptionalText/optionalText';

const CheckboxList = (props: ICheckbox) => {
  const {
    items,
    selectedItem,
    passedValueIndex,
    onChangeItem,
    align,
    disabled = false,
    disabledByIndex,
    marginBottom,
  } = props;

  const [selIndex, setSelIndex] = useState<number | undefined>(passedValueIndex);

  useEffect(() => {
    if (passedValueIndex !== undefined && selectedItem) {
      selectedItem(items[passedValueIndex]);
      setSelIndex(passedValueIndex);
    }
  }, [passedValueIndex]);

  return (
    <>
      {Children.toArray(
        items.map((item, index) => (
          <Container
            disabled={disabledByIndex?.index === index ? disabledByIndex?.state : false}
            marginBottom={marginBottom}
            className="checkbox-container"
            index={index}
            colorIndex={selIndex}
            onClick={() => {
              if (disabled || disabledByIndex?.index === index) return;
              if (index != selIndex) {
                setSelIndex(index);
                if (selectedItem) selectedItem(item);
                if (onChangeItem) onChangeItem(item, index);
              }
            }}
            style={disabled ? { cursor: 'wait' } : {}}
          >
            <CheckboxContainer align={align}>
              {selIndex === index ? <RadioButtonSelected /> : <RadioButtonUnselected />}
            </CheckboxContainer>

            <OptionalText
              detail={item.detail}
              title={item.title}
              description={item.description}
              extra={item.extra}
              value={item.value}
              titleIcon={item.titleIcon}
            />
          </Container>
        ))
      )}
    </>
  );
};

export default CheckboxList;
