import { ButtonSlide, CustomText, ImageAtoms, ImageDivider } from '@vfit/shared/atoms';
import { colors, purify } from '@vfit/shared/themes';
import { useDeviceType } from '@vfit/shared/hooks';
import { Fade } from 'react-awesome-reveal';
import { IAppDownload } from './QRModule.models';
import {
  ButtonContainer,
  Container,
  DescriptionCard,
  QRCode,
  QRCodeCard,
  Store,
  StoreContainer,
  DisclaimerContainer,
  Title,
  Description,
  MainContainer,
} from './QRModule.style';

const QRModule = ({
  title,
  descriptionDesktop,
  descriptionMobile,
  storeList,
  buttonLink,
  buttonLabel,
  headerImage,
  qrCodeImage,
  disclaimer,
  divider,
}: IAppDownload) => {
  const { isDesktop } = useDeviceType();
  const [pTitle, pDescriptionDesktop, pDescriptionMobile] = purify([
    title,
    descriptionDesktop,
    descriptionMobile,
  ]);
  return (
    <MainContainer>
      <Container>
        <DescriptionCard>
          <ImageAtoms
            nameCard="appDownload"
            image={headerImage}
            imageMobile={headerImage}
            style={{
              height: '75px',
              width: 'auto',
            }}
            isMobile
          />
          {pTitle && <Title dangerouslySetInnerHTML={{ __html: pTitle }} />}
          {pDescriptionDesktop && pDescriptionMobile && (
            <Description
              dangerouslySetInnerHTML={{
                __html: isDesktop ? pDescriptionDesktop : pDescriptionMobile,
              }}
            />
          )}
          {isDesktop && (
            <StoreContainer>
              {storeList?.map((store) => (
                <Store>
                  <a href={store?.action?.url}>
                    <ImageAtoms
                      nameCard="appDownload"
                      image={store.image}
                      style={{
                        height: 'auto',
                        width: 'auto',
                      }}
                    />
                  </a>
                </Store>
              ))}
            </StoreContainer>
          )}
          {!isDesktop && buttonLabel && (
            <ButtonContainer>
              <a href={buttonLink}>
                <ButtonSlide
                  onClick={() => {}}
                  label={buttonLabel}
                  buttonColor={colors.$e60000}
                  labelColor={colors.$ffffff}
                />
              </a>
            </ButtonContainer>
          )}
        </DescriptionCard>
        {isDesktop && (
          <QRCodeCard>
            <QRCode>
              <ImageAtoms
                nameCard="appDownload"
                image={qrCodeImage}
                objectFit="cover"
                style={{
                  borderTopRightRadius: "20px",
                  borderBottomRightRadius: "20px"
                }}
              />
            </QRCode>
          </QRCodeCard>
        )}
      </Container>
      {disclaimer && (
        <DisclaimerContainer>
          <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
        </DisclaimerContainer>
      )}
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider image={divider?.image} alt={divider.text || ''} />
        </Fade>
      )}
    </MainContainer>
  );
};

export default QRModule;

