import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: inherit;

  @media (min-width: ${breakpoints.tablet}) {
    margin: 0 auto;
  }
`;

export const Divider = styled.div`
  height: ${pxToRem(1)};
  background-color: ${colors.$bebebe};
  margin: ${pxToRem(36, 0, 32)};

  @media (min-width: ${breakpoints.desktop}) {
    margin: ${pxToRem(45, 0, 40)};
  }
`;

export const DetailButton = styled.div`
  display: flex;
  margin-top: ${pxToRem(24)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: ${pxToRem(32)};
  }
`;

export const DetailTitle = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  color: ${colors.$262626};
  ${pxToCssFont(30, 38)}
  margin-bottom: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: ${pxToRem(24)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
    margin-bottom: ${pxToRem(32)};
  }
`;

export const DetailDesc = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$262626};
  ${pxToCssFont(20, 26)}
  margin-bottom: ${pxToRem(32)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
    margin-bottom: ${pxToRem(60)};
  }
`;

export const ButtonDiv = styled.div`
  display: flex;
  justify-content: center;

  div {
    width: 100%;

    @media (min-width: ${breakpoints.tablet}) {
      width: ${pxToRem(258)};
    }

    @media (min-width: ${breakpoints.desktop}) {
      width: ${pxToRem(296)};
    }
  }
`;

