import { IAmount } from '@vfit/shared/models';
import * as S from './amount.style';

export const Amount: React.FC<IAmount> = ({
  amount,
  isBlock,
  oldAmount,
  recurrence,
  freeAmountRecurrence,
  separator,
  variant,
}) => (
  <S.SpanWrapper isBlock={isBlock}>
    {oldAmount && <S.OldAmount variant={variant}>{oldAmount} </S.OldAmount>}
    {freeAmountRecurrence ? (
      <S.FreeAmountRecurrence variant={variant} dangerouslySetInnerHTML={{ __html: freeAmountRecurrence }} />
    ) : (
      <>
        {amount && <S.Amount variant={variant} dangerouslySetInnerHTML={{ __html: amount }} />}
        {recurrence && (
          <S.Recurrence variant={variant}>
            {separator ||
              (variant === 'details' || variant === 'mva:details' || variant === 'freeAmount'
                ? ' '
                : '/')}
            {recurrence}
          </S.Recurrence>
        )}
      </>
    )}
  </S.SpanWrapper>
);
