
import { CustomText, ImageAtoms } from "@vfit/shared/atoms";
import { ItemContainer, ItemTextContainer } from "./iconListItem.style";
import { Check } from "@vfit/shared-icons";
import { IIconLIstItemProps } from "./iconListItem.models";

const IconListItem = ({icon, text}: IIconLIstItemProps) => {

    return (
        <ItemContainer>
        {icon ? (
          <ImageAtoms
            style={{
              objectFit: 'contain',
              width: '19px',
              marginRight: '15px',
            }}
            nameCard="listIcon"
            image={icon}
          />
        ) :
            <Check />
        }
        <ItemTextContainer>
          <CustomText
            text={text}
            textAlign="left"
            fontFamily="Vodafone Rg"
            size={18}
            lineHeight={24}
          />
        </ItemTextContainer>
      </ItemContainer>
    );

}

export default IconListItem;
