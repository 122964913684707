import { Shadow } from '@vfit/shared/atoms';
import { Container, Wrapper } from './Skeleton.style';
import { ISkeleton } from './Skeleton.models';

export const Skeleton: React.FC<ISkeleton> = ({ groups, margins, tabletMargins }) => (
  <Wrapper margins={margins || [0]} tabletMargins={tabletMargins || [0]}>
    {groups ? (
      <>
        {groups.map((obj, ind) => (
          <div key={ind}>
            {Array.from(Array(obj.repeat != undefined ? obj.repeat : 1).keys()).map((e, i, a) => (
              <Container itemsDistance={i < a.length - 1 ? obj.gap : obj.marginBottom} key={i}>
                <Shadow height={obj.heights} width={obj.width} />
              </Container>
            ))}
          </div>
        ))}
      </>
    ) : (
      <Container itemsDistance={34}>
        {Array.from(Array(5).keys()).map((e) => (
          <Shadow key={e} height={45} />
        ))}
      </Container>
    )}
  </Wrapper>
);

export default Skeleton;
