import { Children } from 'react';
import { ButtonSlide, CustomText, ListItemsWithImage } from '@vfit/shared/atoms';
import DOMPurify from 'isomorphic-dompurify';
import { IAnchors } from '@vfit/shared/models';
import { purify } from '@vfit/shared/themes';
import { IHomeLinksProps } from './anchors.models';
import {
  AnchorContainer,
  AnchorText,
  AnchorTextWrapper,
  AnchorTitle,
  ArrowButton,
  HomeLinksContainer,
  CustomDesc,
} from './anchors.style';

const Anchors = ({
  anchors,
  text,
  textAlign,
  textSize,
  description,
  onTrack,
  anchorScroll,
  isImageAnchors,
}: IHomeLinksProps) => {
  const [pDesc] = purify([description || '']);

  const handlerClick = (anchor: IAnchors) => {
    if (onTrack) {
      window.open(anchor.url, '_blank');
    }
  };

  return (
    <div id={anchorScroll ? 'anchorScroll' : ''}>
      {text && (
        <CustomText textAlign={isImageAnchors ? 'left' : textAlign} size={textSize} text={text} />
      )}
      {pDesc && <CustomDesc dangerouslySetInnerHTML={{ __html: pDesc || '' }} />}
      <HomeLinksContainer>
        {!isImageAnchors &&
          Children.toArray(
            anchors?.map((anchor) => {
              const sanitizeText = DOMPurify.sanitize(anchor.text);
              return (
                <AnchorContainer isEven={anchors.length % 2 === 0}>
                  <AnchorTextWrapper>
                    <AnchorTitle>{anchor.title}</AnchorTitle>
                    <AnchorText dangerouslySetInnerHTML={{ __html: sanitizeText }} />
                  </AnchorTextWrapper>
                  <ArrowButton>
                    {anchor?.title && anchor?.url && (
                      <ButtonSlide
                        onClick={() => handlerClick(anchor)}
                        small
                        label={anchor.title}
                        iconColor="#fff"
                        buttonColor="#e60000"
                        hoverColor="#bd0000"
                        clickColor="#a10000"
                        smallSize={35}
                        arrowWidth={9}
                        arrowHeigth={10}
                        name="action_anchor_genericDetail"
                      />
                    )}
                  </ArrowButton>
                </AnchorContainer>
              );
            })
          )}
        {isImageAnchors && (
          <ListItemsWithImage
            textColor="#262626"
            elements={
              anchors?.map((e) => ({
                text: e?.text || '',
                icon: e?.image || '',
                subtext: e?.title || '',
              })) || []
            }
          />
        )}
      </HomeLinksContainer>
    </div>
  );
};

export default Anchors;
