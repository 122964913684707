import React from 'react';
import { IWarningInfo } from '@vfit/shared/models';
import { Container, Title, Subtitle, Description } from './warningInfo.style';
import { requireStaticImageAtomComponent } from '@vfit/shared/data-access';
import { Player } from '@lottiefiles/react-lottie-player';

export const WarningInfo = ({ title, subtitle, description, lottie }: IWarningInfo) => (
  <Container>
    {!lottie && (
      <>
        <Title>{title}</Title>
        <Subtitle>{subtitle}</Subtitle>
        <Description>{description}</Description>
      </>
    )}
    {lottie && (
      <Player
        className="LockInPlayer"
        src={requireStaticImageAtomComponent(lottie).src}
        loop
        autoplay
      />
    )}
  </Container>
);

export default WarningInfo;
