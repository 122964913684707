import { IAddressErrors } from '@vfit/shared/models';
import * as yup from 'yup';

export const schema = (cmsConfig?: IAddressErrors) =>
  yup
    .object({
      city: yup
        .string()
        .min(1, cmsConfig?.city?.min || 'Inserire una via valida')
        .matches(
          /^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ.\s-]*$/g,
          cmsConfig?.city?.matchRegex || 'Inserire una via valida'
        )
        .required(cmsConfig?.city?.required || 'Tutti i campi sono obbligatori'),
      postalCode: yup
        .string()
        .min(1, cmsConfig?.postalCode?.min || 'Inserire un codice postale valido')
        .matches(
          /^\d{5}$/,
          cmsConfig?.postalCode?.matchRegex || 'Inserire un codice postale valido'
        )
        .required(cmsConfig?.postalCode?.required || 'Tutti i campi sono obbligatori'),
      stateOrProvince: yup
        .string()
        .min(1, cmsConfig?.stateOrProvince?.min || 'Inserire una regione valida')
        .matches(
          /^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ\s-]*$/g,
          cmsConfig?.stateOrProvince?.matchRegex || 'Inserire una regione valida'
        )
        .required(cmsConfig?.stateOrProvince?.required || 'Tutti i campi sono obbligatori'),
      street: yup
        .string()
        .min(1, cmsConfig?.stateOrProvince?.min || '')
        .test(
          'rangeTest',
          cmsConfig?.street?.matchRegex || ' ',
          (value) => !(value === undefined || value.trim().length === 0)
        )
        .matches(/^[a-zA-Z'àèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝ.\s-]*$/g, cmsConfig?.street?.matchRegex || '')
        .required(cmsConfig?.street?.required || 'Tutti i campi sono obbligatori'),
      streetNumber: yup
        .string()
        .test('rangeTest', cmsConfig?.streetNumber?.matchRegex || ' ', (value) => {
          if (value?.match(/^[1-9]([0-9]{0,5})(\/?)[a-zA-Z]?$/)) {
            return true;
          }
          return false;
        })
        .required(cmsConfig?.streetNumber?.required || 'Tutti i campi sono obbligatori'),
    })
    .required();

