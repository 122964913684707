import React, {Children} from "react";
import {SectionStickContainer, Stick} from './sectionStick.style';
import {ISectionStickProps} from "./sectionStick.models";

const SectionStick = ({currentSlide, onGoToSlide, slides}: ISectionStickProps) => (
  <SectionStickContainer>
    {
      Children.toArray(
        slides.map((s, indexSlide) => <Stick onClick={() => onGoToSlide(indexSlide)} active={indexSlide === currentSlide}/>)
      )
    }
  </SectionStickContainer>
)

export default SectionStick;
