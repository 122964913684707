import { checkWindow } from '@vfit/shared/data-access';

const DEFAULT_HEIGHT = {
  tablet: '608.5px',
  desktop: '633.5px',
};

const updateVisibility = (index: number) => {
  if (!checkWindow()) return;

  const visibilityFunction = () => {
    const stepperElements: HTMLCollectionOf<HTMLElement> = document?.getElementsByClassName(
      'stepper-children'
    ) as HTMLCollectionOf<HTMLElement>;
    if (stepperElements?.length > 0) {
      Array.from(stepperElements).forEach((el) => {
        if (el) {
          // eslint-disable-next-line no-param-reassign
          el.style.display = 'none';
        }
      });
      stepperElements[index].style.display = 'block';
    }
  };

  setTimeout(() => {
    visibilityFunction();
  }, 0);
};

export { DEFAULT_HEIGHT, updateVisibility };
