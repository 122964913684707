const MAP_OPTIONS = {
  fullscreenControl: false,
  keyboardShortcuts: false,
  mapTypeControl: false,
  showsUserLocation: true,
  showsMyLocationButton: true,
  zoomControl: false,
  zoomControlOptions: { position: 3 },
  styles: [
    {
      elementType: 'geometry',
      stylers: [],
    },
    {
      elementType: 'labels',
      stylers: [
        {
          visibility: 'on',
        },
      ],
    },
    {
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'administrative',
      elementType: 'geometry',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'administrative',
      elementType: 'labels',
      stylers: [
        {
          weight: 4.5,
        },
      ],
    },
    {
      featureType: 'administrative.land_parcel',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'landscape',
      elementType: 'geometry.fill',
      stylers: [],
    },
    {
      featureType: 'poi',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'poi',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'poi',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'poi.park',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'poi.park',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'road',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'road',
      elementType: 'geometry.fill',
      stylers: [],
    },
    {
      featureType: 'road',
      elementType: 'geometry.stroke',
      stylers: [],
    },
    {
      featureType: 'road',
      elementType: 'labels.icon',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'road.arterial',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'road.highway',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'road.highway',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'road.local',
      elementType: 'labels.text.fill',
      stylers: [],
    },
    {
      featureType: 'transit',
      stylers: [
        {
          visibility: 'off',
        },
      ],
    },
    {
      featureType: 'transit.line',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'transit.station',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'water',
      elementType: 'geometry',
      stylers: [],
    },
    {
      featureType: 'water',
      elementType: 'labels.text.fill',
      stylers: [],
    },
  ],
};

const MAP_CONFIG = {
  key: process.env['NX_GOOGLE_MAPS_API_KEY'] || '',
  libraries: ['places'],
  language: 'it',
  region: 'it',
  version: 'weekly',
};

export { MAP_OPTIONS, MAP_CONFIG };
