import { ICountry, IProvince } from '@vfit/shared/data-access';
import { useAddressGeocoder } from '@vfit/shared/hooks';
import { IAddressObject } from '@vfit/shared/models';
import { FieldErrorsImpl, UseFormSetError } from 'react-hook-form';
import { IPicked } from './addressManual.models';

export const checkIsValidProvince = (province: string, italianProvinces: IProvince[]) => {
  const provinceNameLong = (province.match(/^[^(]+/) || ' ')[0].trim();
  const provinceNameShort = (province.match(/\((.*?)\)/) || '')[1];
  if (provinceNameLong && provinceNameShort) {
    return italianProvinces.find(
      (italianProvince) =>
        italianProvince.long === provinceNameLong && italianProvince.short === provinceNameShort
    );
  }
  return undefined;
};

export const checkIsValidCity = (city: string, italianMunicipalities: ICountry[]) => {
  const cityName = (city.match(/^[^(]+/) || ' ')[0].trim();
  const provinceOfCity = (city.match(/\((.*?)\)/) || '')[1];
  if (cityName && provinceOfCity) {
    return italianMunicipalities.find(
      (italianMunicipality) =>
        italianMunicipality.municipality === cityName &&
        italianMunicipality.province === provinceOfCity
    );
  }
  return undefined;
};

export const retrieveAddressString = (inputs: IAddressObject) =>
  `${inputs.city}, ${inputs.postalCode}, ${inputs.stateOrProvince}, ${inputs.street}, ${inputs.streetNumber}`;

const GetAddressObjectFromString = async (text: string) =>
  Promise.resolve(await useAddressGeocoder(text));

/**
 *
 * @param addressString address retrieved from Form
 * @param setError Form method UseFormSetError<IAddressObject>
 * @param errors FieldErrorsImpl same key as IAddressObject
 * @returns This method returns the address formatted and validated by google
 */
// eslint-disable-next-line react-hooks/rules-of-hooks
export const retrieveAddressObjectFromString = async (
  addressString: string,
  setError: UseFormSetError<IAddressObject>,
  errors: Partial<
    FieldErrorsImpl<{
      city: string;
      postalCode: string;
      stateOrProvince: string;
      street: string;
      streetNumber: string;
    }>
  >
) => {
  const addressGeocoder = await GetAddressObjectFromString(addressString).catch(() => null);
  if (addressGeocoder) {
    const pikedForMutation = (({ region, latitude, longitude, placeId }) => ({
      region,
      latitude,
      longitude,
      placeId,
    }))(addressGeocoder);
    const picked = (({ city, postalCode, stateOrProvince, street, streetNumber }) => ({
      street,
      streetNumber,
      stateOrProvince,
      city,
      postalCode,
    }))(addressGeocoder);
    if (Object.values(picked).some((el) => el === '')) {
      let errorFocus = false;
      // eslint-disable-next-line array-callback-return
      Object.entries(picked).filter((keyValueArray) => {
        if (keyValueArray[1] === '') {
          setError(
            keyValueArray[0] as IPicked,
            { message: errors?.[keyValueArray[0]]?.message },
            { shouldFocus: !errorFocus }
          );
          errorFocus = true;
        }
        return { picked, pikedForMutation };
      });
    }
    return { picked, pikedForMutation };
  }
  return null;
};

