import { breakpoints } from '@vfit/shared/themes';
import styled from 'styled-components';

export const DocumentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 64px 24px;
  gap: 32px;

  @media (min-width: ${breakpoints.desktop}) {
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 80px;
  }
`;

export const CustomTextLabelWrapper = styled.div`
  div {
    margin: 0;

    * {
      margin: 0;
    }
  }
`;

export const DocumentsItemsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0;
  gap: 32px;

  @media (min-width: ${breakpoints.tablet}) {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    padding: 0;
    gap: 0;
    flex: none;
    order: 1;
    flex-grow: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    width: 100%;
    grid-template-columns: repeat(auto-fill, 405px);
    justify-content: flex-start;
    display: grid;
    gap: 32px;
  }
`;

export const CustomTextDesctopLabelWrapper = styled.div``;

