import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ExperiencesWrapper = styled.div`
  font-family: ${fonts.regular};
  margin-bottom: 64px;

  * {
    box-sizing: inherit;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    transform-style: preserve-3d;
    animation-fill-mode: unset !important;
    margin-bottom: 180px;
  }
`;

export const ExperiencesContainer = styled.div`
  position: relative;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  border-radius: 15px;
  padding-bottom: 32px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    display: flex;
    align-items: stretch;
    height: 596px;
    position: relative;
    transform-style: inherit;
    padding: 0;
  }
`;

export const ExperiencesContainerLeft = styled.div`
  padding: 56px 24px 32px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    border-radius: 20px 0 0 20px;
    width: 296px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 2;
    position: relative;
    padding: 0;
  }
`;

export const ExperiencesContainerLeftText = styled.div`
  text-align: center;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 0 24px 0 48px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    text-align: left;
  }
`;

export const ExperiencesContainerLeftTitle = styled.div`
  div {
    margin: 0;
  }

  p,
  span {
    margin: 0;
    ${pxToCssFont(35, 38)}
    font-style: normal;
    font-weight: 400;
    color: ${colors.$ffffff};
    font-family: ${fonts.exbold};
    margin-bottom: 12px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    p,
    span {
      ${pxToCssFont(42, 52)}
      margin-bottom: 20px;
    }
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    p,
    span {
      ${pxToCssFont(40, 48)}
      margin-bottom: 20px;
    }
  }
`;

export const ExperiencesContainerLeftParagraph = styled.div`
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.bigDesktop}) {
    ${pxToCssFont(24, 30)}
  }
`;

export const ExperienceContainerButton = styled.div`
  padding-top: 12px;

  button {
    width: 221px;
  }

  @media (min-width: ${breakpoints.tablet}) {
    padding-top: 16px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    padding-top: 26px;

    button {
      width: 100%;
    }
  }
`;

export const ExperiencesLayoutColumn = styled.div`
  display: inline-block;
  white-space: nowrap;

  @media (min-width: ${breakpoints.bigDesktop}) {
    white-space: unset;
    width: 100%;
    max-width: 320px;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    margin-top: -14px;
    align-items: end;
  }

  :first-child {
    div:first-child {
      margin-left: 24px;

      @media (min-width: ${breakpoints.bigDesktop}) {
        margin-left: 0;
      }
    }

    @media (min-width: ${breakpoints.bigDesktop}) {
      transform: translateY(64px);
      margin-top: 0;
    }
  }

  :last-child {
    @media (min-width: ${breakpoints.bigDesktop}) {
      border-radius: 0 20px 20px 0;
      margin-top: 0;
    }
  }
`;

export const ExperiencesContainerRight = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  white-space: nowrap;
  width: 100%;
  -ms-overflow-style: none;
  scrollbar-width: none;
  padding-left: 24px;
  margin-bottom: 24px;
  display: flex;
  will-change: auto;

  &::-webkit-scrollbar {
    display: none;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    overflow: visible;
    white-space: unset;
    transform-origin: center;
    height: 100%;
    width: calc(100% - 296px);
    display: flex;
    align-items: stretch;
    padding: 0;
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    &.layout-3,
    &.layout-4 {
      justify-content: flex-end;

      /* stylelint-disable-next-line selector-type-no-unknown */
      ${ExperiencesLayoutColumn}:first-child {
        padding-left: 0;
        margin-right: 24px;
        align-items: end;
      }

      /* stylelint-disable-next-line selector-type-no-unknown */
      ${ExperiencesLayoutColumn}:last-child {
        display: none;
      }

      ${ExperiencesLayoutColumn} {
        width: unset;
      }
    }

    &.layout-6 {
      /* stylelint-disable-next-line selector-type-no-unknown */
      ${ExperiencesLayoutColumn}:last-child {
        transform: translateY(64px);
      }
    }
  }

  transition-duration: 0.3s;
  transition-timing-function: ease-in;
  transition-delay: 0s;
`;

export const Parallax = styled.div`
  // perspective: 9px;
  // transform-style: preserve-3d;
  // perspective-origin: center;
`;

export const DivImgContainer = styled.div<{ absolute: boolean }>`
  height: 100%;
  width: 100%;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
  z-index: -1;
  object-fit: cover;
  object-position: center;
  border-radius: 15px;
  position: ${(props) => (props.absolute ? 'absolute' : 'relative')};
  top: ${(props) => (props.absolute ? 0 : '')};
  left: ${(props) => (props.absolute ? 0 : '')};
`;

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;

