import React from 'react';
import { ImageAtoms } from '@vfit/shared/atoms';
import {
  ExperienceCard,
  ExperienceCardArt,
  ExperienceCardFooter,
  ExperienceCardTitle,
  ExperienceCardDesc,
  ExperienceCardFooterImgContainer,
  ExperienceCardInternalFooter,
} from './card.style';
import { ICard } from './card.models';

const Card: React.FC<ICard> = ({
  image,
  title,
  description,
  maskIconSize,
  maskIcon,
  handleClickCard,
  action,
}) => (
  <ExperienceCard onClick={() => handleClickCard && handleClickCard(action)}>
    <ExperienceCardArt>
      <ImageAtoms nameCard="ExperienceCard" image={image} />
    </ExperienceCardArt>
    <ExperienceCardFooter>
      <ExperienceCardInternalFooter>
        <div>
          <ExperienceCardTitle>{title}</ExperienceCardTitle>
          <ExperienceCardDesc>{description}</ExperienceCardDesc>
        </div>
        {maskIcon && (
          <ExperienceCardFooterImgContainer>
            <ImageAtoms
              style={{
                objectFit: 'contain',
                width: `${maskIconSize || 50}px`,
              }}
              nameCard="maskIcon"
              image={maskIcon}
            />
          </ExperienceCardFooterImgContainer>
        )}
      </ExperienceCardInternalFooter>
    </ExperienceCardFooter>
  </ExperienceCard>
);

export default Card;
