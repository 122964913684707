/**
 * Method to scroll to ref section or anchor if section not exists
 * @param refSection
 * @param anchorEl
 */
const scrollToSection = (refSection: HTMLDivElement | null, anchorEl: HTMLElement | null, offset?: number) => {
    if (refSection) {
        refSection.scrollIntoView({
            behavior: 'smooth',
        });
    } else if (offset === undefined) {
        anchorEl?.scrollIntoView({
            behavior: 'smooth',
        });
    } else {
        const viewportYDelta = anchorEl?.getBoundingClientRect().top || 0;
        const y = window.pageYOffset + viewportYDelta + offset;
        window.scrollTo({ top: y, behavior: 'smooth' });
    }
};

export { scrollToSection };
