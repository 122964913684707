import styled from 'styled-components';
import { breakpoints, colors } from '@vfit/shared/themes';
import { ICSSContainer } from './ModalStepper.models';

export const Background = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  background-color: #000000c4;
`;

export const Container = styled.div<ICSSContainer>`
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  top: ${(props) => (props.withBorderRadius ? '66' : '0')}px;
  border-top-left-radius: ${(props) => (props.withBorderRadius ? '20' : '0')}px;
  border-top-right-radius: ${(props) => (props.withBorderRadius ? '20' : '0')}px;
  background-color: ${colors.$ffffff};
  overflow: hidden;

  @media (min-width: ${breakpoints.tablet}) {
    left: 50%;
    top: 50%;
    display: table;
    transform: translate(-50%, -50%);
    width: 62.2%;
    border-radius: 20px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    left: 50%;
    top: 50%;
    display: table;
    transform: translate(-50%, -50%);
    max-width: 842px;
  }

  .closing-icon {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    top: 21.5px;
    right: 21.5px;

    @media (min-width: ${breakpoints.tablet}) {
      top: 25.5px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      top: 29.5px;
      right: 29.5px;
    }

    &:hover {
      cursor: pointer;
    }
  }

  .back-icon {
    position: relative;
    display: flex;
    align-items: center;
    top: 8.5px;
    left: 18.75px;
    height: 17px;
    width: 18.75px;

    @media (min-width: ${breakpoints.tablet}) {
      top: 10.5px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      top: 14.5px;
      left: 26.75px;
    }
  }

  .back-icon:hover,
  .closing-icon:hover {
    cursor: pointer;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${colors.$ffffff};
  z-index: 9999;
  width: 100%;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 36px;

  @media (min-width: ${breakpoints.tablet}) {
    height: 38.5px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: 42.5px;
  }
`;

export const ModalContent = styled.div<{
  height: { tablet: string; desktop: string } | undefined;
}>`
  max-height: 90vh;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;
  -ms-overflow-style: none; /* for Internet Explorer, Edge */

  ::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }

  @media (min-width: ${breakpoints.tablet}) {
    height: ${(props) => props?.height?.tablet};
  }

  @media (min-width: ${breakpoints.desktop}) {
    height: ${(props) => props?.height?.desktop};
  }
`;

// TODO: wip FIGMA modal error styles
export const ContainerModalError = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: ${colors.$ffffff};

  @media (min-width: ${breakpoints.tablet}) {
    left: 50%;
    top: 50%;
    display: table;
    transform: translate(-50%, -50%);
    width: 62.2%;
  }

  .closing-icon:hover {
    cursor: pointer;
  }
`;

export const ModalErrorHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 25px;
  border-bottom: 1px solid #5e5e5e;
`;

export const ModalErrorContent = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 30px 25px 25px;
`;

export const ModalErrorFooter = styled.div`
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 25px;

  button {
    text-transform: uppercase;
    max-width: 300px;
  }
`;
