import styled from 'styled-components';
import { breakpoints, colors, fonts } from '@vfit/shared/themes';

export const DisclaimerContainer = styled.div`
  font-family: ${fonts.regular};
  font-weight: 700;
  color: ${colors.$7e7e7e};

  img {
    width: 18px;
  }
`;

export const Title = styled.div`
  div {
    padding: 32px 0;
    margin-bottom: 0;

    p {
      margin-block: 0;
    }

    @media (min-width: ${breakpoints.tablet}) {
      padding: 80px 0 48px;
    }

    @media (min-width: ${breakpoints.desktop}) {
      padding: 80px 0 84px;
    }
  }
`;

