import { useMemo } from 'react';
import { purify } from '@vfit/shared/themes';
import { IUsePrice } from '@vfit/shared/models';

export const usePrice = ({ amount, detail, label, note, specs, description, freeAmountRecurrence }: IUsePrice) => {
  const [pDetail, pNote, pDescription, _pAmount, pFreeAmountRecurrence] = purify([detail, note, description, amount || '', freeAmountRecurrence]);

  const isOnlyAmount = useMemo(
    () => amount && !detail && !label && !note && !specs,
    [amount, detail, label, note, specs]
  );


  const pAmount = _pAmount || ''

  return { isOnlyAmount, pDetail, pNote, pDescription, pAmount, pFreeAmountRecurrence };
};
