import React, { useEffect } from 'react';
import { Portal } from '@vfit/shared/components';
import { ModalStepper } from '@vfit/shared/atoms';
import { OverlayGlobalStyle } from './vfModalStepper.style';
import { IVfModalProps } from './vfModalStepper.models';

const VfModalStepper = ({
  id,
  isOpen,
  handleClose,
  onBack,
  height,
  children,
  showBackCta,
}: IVfModalProps) => {
  useEffect(() => {
    const closeOnEscapeKey = (e: KeyboardEvent) => (e.key === 'Escape' ? handleClose() : null);
    document.body.addEventListener('keydown', closeOnEscapeKey);
    return () => {
      document.body.removeEventListener('keydown', closeOnEscapeKey);
    };
  }, [handleClose]);

  return isOpen ? (
    <Portal wrapperId={id}>
      <>
        <OverlayGlobalStyle />
        <ModalStepper
          showBackCta={showBackCta || false}
          onBack={onBack}
          withBorderRadius
          show={isOpen}
          onCloseModal={handleClose}
          height={height}
        >
          {children}
        </ModalStepper>
      </>
    </Portal>
  ) : null;
};

export default VfModalStepper;
