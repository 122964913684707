import {useEffect, useState} from "react";

export enum ScrollDirection {
  up = "up",
  down = "down",
}

interface IUseScrollDirectionExp {
  isVisible: boolean
}

const useScrollDirectionExp = ({isVisible}: IUseScrollDirectionExp) => {
  const threshold = 100;
  const [scrollDir, setScrollDir] = useState(ScrollDirection.up);
  const [translateY, setTranslateY] = useState<number>(0);

  useEffect(() => {
    let previousScrollYPosition = window.scrollY;

    const scrolledMoreThanThreshold = (currentScrollYPosition: number) =>
      Math.abs(currentScrollYPosition - previousScrollYPosition) > threshold;

    const isScrollingUp = (currentScrollYPosition: number) =>
      currentScrollYPosition > previousScrollYPosition &&
      !(previousScrollYPosition > 0 && currentScrollYPosition === 0) &&
      !(currentScrollYPosition > 0 && previousScrollYPosition === 0);

    const updateScrollDirection = () => {
      const currentScrollYPosition = window.scrollY;

      if (scrolledMoreThanThreshold(currentScrollYPosition)) {
        const newScrollDirection = isScrollingUp(currentScrollYPosition)
          ? ScrollDirection.down
          : ScrollDirection.up;
        setScrollDir(newScrollDirection);
        previousScrollYPosition =
          currentScrollYPosition > 0 ? currentScrollYPosition : 0;
        // Check on visibility
        if (isVisible) {
          if (newScrollDirection === ScrollDirection.up) {
            setTranslateY((prevState) => {
              const res = prevState + 15
              return res >= 75 ? 75 : res;
            })
          } else {
            setTranslateY((prevState) => {
              const res = prevState - 15
              return res <= -75 ? -75 : res;
            })
          }
        }

      }
    };

    const onScroll = () => window.requestAnimationFrame(updateScrollDirection);

    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [isVisible]);

  return {scrollDir, translateY};
};

export {
  useScrollDirectionExp
}
