import styled from 'styled-components';
import { breakpoints } from '@vfit/shared/themes';

export const ScrollButtonAndStickWrapper = styled.div<{ slidesLenght: number }>`
  position: sticky;
  bottom: 0;
  z-index: ${({ slidesLenght }) => slidesLenght};
`;

export const ScrollButtonContainer = styled.div`
  position: absolute;
  bottom: 23px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  pointer-events: none;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 52px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    bottom: 64px;
  }
`;

export const ButtonScroll = styled.div`
  justify-content: center;
  display: flex;
  pointer-events: all;
  transform: scale(0.7);

  @media (min-width: ${breakpoints.desktop}) {
    transform: scale(1);
  }
`;

export const ScrollStickContainer = styled.div`
  position: absolute;
  bottom: 35px;
  right: 21px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  @media (min-width: ${breakpoints.tablet}) {
    bottom: 64px;
    right: 27px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    right: 35px;
  }
`;

