import React, { Children, MutableRefObject, useCallback, useEffect, useRef, useState } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { useDeviceType, useViewportHeight } from '@vfit/shared/hooks';
import {
  ButtonScroll,
  ScrollButtonContainer,
  ScrollStickContainer,
  ScrollButtonAndStickWrapper,
} from './heroSliderDefault.style';
import { ISection, ITopHomeProps, ScrollDirection } from './heroSliderDefault.models';
import SnapScroll from './components/SnapScroll/snapScroll';
import SectionStick from './components/SectionStick/sectionStick';

const HeroSliderDefault = ({ slides, onTrack }: ITopHomeProps) => {
  const { isDesktop } = useDeviceType();
  const [y, setY] = useState<number | undefined>(document.scrollingElement?.scrollHeight);
  const [scrollDirection, setScrollDirection] = useState<string>('');
  const [activeSection, setActiveSection] = useState<ISection>({
    index: 0,
    ref: null,
  });
  const refs = useRef<HTMLDivElement[] | null[]>([]);
  const anchorRef = useRef<HTMLElement | null>(null);
  const mainContainerRef = useRef<HTMLElement | null | Window>(null);
  // const lastRef = useRef<HTMLDivElement | null>(null);
  // const lastRefEntry = useIntersectionObserver(lastRef, {rootMargin: '-500px'});
  // const isVisibleLastEntry = !!lastRefEntry?.isIntersecting;

  const { headerHeight, viewportHeight } = useViewportHeight();

  const scrollToSlideSection = (slideIndex: number) => {
    const element = refs.current[slideIndex];
    const viewportYDelta = element?.getBoundingClientRect().top || 0;
    const elementY = window.pageYOffset + viewportYDelta;
    window.scrollTo({ top: elementY, behavior: 'smooth' });
  };

  const scrollToAnchorScroll = () => {
    const element = document.getElementById('anchorScroll');
    const viewportYDelta = element?.getBoundingClientRect().top || 0;
    const elementY = window.pageYOffset + viewportYDelta - headerHeight;
    window.scrollTo({ top: elementY, behavior: 'smooth' });
  };

  const onButtonClick = () => {
    if (activeSection.index < slides.length - 1) {
      scrollToSlideSection(activeSection.index + 1);
    } else {
      scrollToAnchorScroll();
    }
  };

  // const scrollToElement = (elementRef: HTMLDivElement) => {
  //   window.scrollTo({
  //     behavior: 'smooth',
  //     top: elementRef.offsetTop
  //   })
  // }

  const handleNavigation = useCallback(
    (evt: any) => {
      const scrollTop = isDesktop ? evt.target.scrollTop : window.scrollY;
      if (y) {
        if (y > scrollTop) {
          setScrollDirection(ScrollDirection.UP);
        } else if (y < scrollTop) {
          setScrollDirection(ScrollDirection.DOWN);
        }
      }
      setY(scrollTop);
    },
    [isDesktop, y]
  );

  const changeVisibilitySection = (visibilitySection: ISection) => {
    const { index: section, ref } = visibilitySection;
    const { index: currentSection } = activeSection;
    if (section !== currentSection) {
      if (scrollDirection === ScrollDirection.UP && section < currentSection) {
        setActiveSection({
          index: section,
          ref,
        });
      } else if (scrollDirection === ScrollDirection.DOWN && section > currentSection) {
        setActiveSection({
          index: section,
          ref,
        });
      }
    }
  };

  // useEffect(() => {
  //   if (activeSection.ref) {
  //     LoggerInstance.debug(activeSection)
  //     // TO FIX
  //     // scrollToElement(activeSection.ref);
  //   }
  // }, [activeSection]);

  useEffect(() => {
    anchorRef.current = document.getElementById('anchorScroll');
    mainContainerRef.current = isDesktop ? document.getElementById('mainContainer') : window;
    mainContainerRef?.current?.addEventListener('scroll', handleNavigation);
    return () => {
      mainContainerRef?.current?.removeEventListener('scroll', handleNavigation);
    };
  }, [handleNavigation, isDesktop]);

  // useEffect(() => {
  //   if (isVisibleLastEntry && lastRef?.current) {
  //     if (scrollDirection === ScrollDirection.DOWN) {
  //       // scrollToElement(lastRef.current);
  //     } else if (refs.current[slides.length - 1]) {
  //       // scrollToElement(refs.current[slides.length - 1] as HTMLDivElement);
  //     }
  //   }
  // }, [isVisibleLastEntry, scrollDirection, slides.length])

  const setSlideRef = (ref: MutableRefObject<HTMLDivElement | null>, indexSlide: number) => {
    refs.current[indexSlide] = ref.current;
  };

  const navigationMenu = () => (
    <ScrollButtonAndStickWrapper slidesLenght={slides.length}>
      <ScrollButtonContainer>
        <Fade>
          <ButtonScroll>
            <ButtonSlide
              smallSize={50}
              label="Go to next slide"
              onClick={onButtonClick}
              small
              animated
              rotation={90}
              buttonColor="#fff"
              hoverColor="rgba(255, 255, 255, 0.8)"
              clickColor="rgba(255, 255, 255, 0.6)"
              name="action_goNext"
            />
          </ButtonScroll>
        </Fade>
      </ScrollButtonContainer>
      <ScrollStickContainer>
        <Fade>
          <SectionStick
            onGoToSlide={scrollToSlideSection}
            currentSlide={activeSection.index}
            slides={slides}
          />
        </Fade>
      </ScrollStickContainer>
    </ScrollButtonAndStickWrapper>
  );

  return (
    <section>
      {Children.toArray(
        slides.map((slide, index) => {
          const { id } = slide;
          return (
            <SnapScroll
              key={id}
              slide={slide}
              onTrack={onTrack}
              zIndex={slides.length - 1 - index}
              setRef={(ref) => setSlideRef(ref, index)}
              indexSlide={index}
              onChangeVisibility={changeVisibilitySection}
              headerHeight={headerHeight}
              viewportHeight={viewportHeight}
            />
          );
        })
      )}
      {navigationMenu()}
    </section>
  );
};

export default HeroSliderDefault;
