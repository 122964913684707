import { ICommonSlides, IOnTrack } from '@vfit/shared/models';

export interface ISection {
  index: number;
  ref: HTMLDivElement | null;
}

export interface ITopHomeProps {
  slides: ICommonSlides[];
  onTrack?: IOnTrack;
}

export enum ScrollDirection {
  UP = 'UP',
  DOWN = 'DOWN',
}

export type AnimationMode = 'slide-animation slide-up' | 'slide-animation slide-down' | '';
