import { useState } from 'react';
import { ButtonSlide, Modal } from '@vfit/shared/atoms';
import { checkIsApp } from '@vfit/consumer/data-access';
import { Container, Title, ButtonContainer } from './modalInfo.style';
import { IModalInfo } from './modalInfo.model';

const ModalInfo: React.FC<IModalInfo> = ({ content, customAction }) => {
  const [isOpen, setIsOpen] = useState(false);
  const { title, ctaName, card } = content;

  return (
    <Container>
      <div>
        <Title>{title}</Title>
        <ButtonContainer isApp={checkIsApp()}>
          <ButtonSlide
            isApp={checkIsApp()}
            labelColor="#262626;"
            borderColor="#262626"
            buttonColor="#fff"
            label={ctaName}
            onClick={() => {
              if (customAction) customAction();
              else setIsOpen((prevState) => !prevState);
            }}
          />
        </ButtonContainer>
      </div>
      {card && (
        <Modal
          show={isOpen}
          onCloseModal={() => setIsOpen((prevState) => !prevState)}
          withBorderRadius
        >
          {card}
        </Modal>
      )}
    </Container>
  );
};
export default ModalInfo;
