import React, { useEffect, useState, useRef, useCallback } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { useViewportHeight, useScrollTop } from '@vfit/shared/hooks';

import { ITopHomeProps, AnimationMode, ScrollDirection } from './heroSliderAnimation.model';
import {
  HeroContainer,
  SliderWrapper,
  OverScrollDisabled,
  ButtonScroll,
  ScrollButtonContainer,
  ScrollStickContainer,
} from './heroSliderAnimation.style';
import SlideHome from './components/SlideHome/slideHome';
import SectionStick from './components/SectionStick/sectionStick';
import { scrollToSection } from './heroSliderAnimation.utils';

const MIN_TOUCH_SWIPE_DISTANCE = 20;
const MIN_MOUSE_SWIPE_DISTANCE = 100;

const HeroSliderAnimation: React.FC<ITopHomeProps> = ({ slides, onTrack }) => {
  useScrollTop();
  const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(ScrollDirection.DOWN);
  const [isMouseDown, setIsMouseDown] = useState(false);
  const [touchStart, setTouchStart] = useState<null | number>(null);
  const [touchEnd, setTouchEnd] = useState<null | number>(null);
  const anchorRef = useRef<HTMLElement | null>(null);
  const [slideIndex, setSlideIndex] = useState(slides.length - 1);
  const [incomingSlideIndex, setIncomingSlideIndex] = useState(-1);
  const heroRef = useRef() as React.MutableRefObject<HTMLDivElement>;
  const mouseDownStartY = useRef<number>(-1);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [enableAnimation, setEnableAnimation] = useState(false);
  const delay = useRef(false);

  const { headerHeight, viewportHeight } = useViewportHeight();

  const onScrollDown = useCallback(
    (index: number) => {
      if (!enableAnimation && slideIndex !== 0) {
        setIncomingSlideIndex(index);
        setScrollDirection(ScrollDirection.UP);
        setEnableAnimation(true);
      }
    },
    [enableAnimation, slideIndex]
  );

  const onScrollUp = useCallback(
    (index: number) => {
      if (!enableAnimation && slideIndex !== slides.length - 1) {
        setIncomingSlideIndex(index);
        setScrollDirection(ScrollDirection.DOWN);
        setEnableAnimation(true);
      }
    },
    [enableAnimation, slideIndex, slides.length]
  );

  const selectAnimationMode = useCallback(
    (index: number): AnimationMode => {
      if (enableAnimation && index === incomingSlideIndex) {
        if (scrollDirection === ScrollDirection.UP) {
          return 'slide-animation slide-up';
        }
        return 'slide-animation slide-down';
      }
      return '';
    },
    [enableAnimation, incomingSlideIndex, scrollDirection]
  );

  const onTouchStart = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(null);
    setTouchStart(e?.targetTouches[0]?.clientY);
  }, []);

  const onTouchMove = useCallback((e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e?.targetTouches[0]?.clientY);
  }, []);

  const onTouchEnd = useCallback(() => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isDownSwipe = distance > MIN_TOUCH_SWIPE_DISTANCE;
    const isUpSwipe = distance < -MIN_TOUCH_SWIPE_DISTANCE;
    if (isDownSwipe) {
      onScrollDown(slideIndex - 1);
    } else if (isUpSwipe) {
      onScrollUp(slideIndex + 1);
    }
  }, [onScrollDown, onScrollUp, slideIndex, touchEnd, touchStart]);

  const onMoveWheel = useCallback(
    (evt: React.WheelEvent<HTMLDivElement>) => {
      if (evt.deltaY < 0) {
        onScrollUp(slideIndex + 1);
      } else if (evt.deltaY > 0) {
        onScrollDown(slideIndex - 1);
      }
    },
    [onScrollDown, onScrollUp, slideIndex]
  );

  const checkAnimation = useCallback(
    (action: () => void) => {
      if (!enableAnimation) {
        if (slideIndex !== 0 || scrollPosition === 0) {
          action();
        }
      }
    },
    [enableAnimation, scrollPosition, slideIndex]
  );

  const delayOnWheel = useCallback(
    (evt: React.WheelEvent<HTMLDivElement>) => {
      evt.stopPropagation();

      if (delay.current || evt.deltaY === 0) {
        return;
      }

      if (Math.abs(evt.deltaY) > 0) {
        delay.current = true;

        setTimeout(() => {
          delay.current = false;
        }, 1000);

        checkAnimation(() => onMoveWheel(evt));
      }
    },
    [checkAnimation, onMoveWheel]
  );

  const onAnimationEndAction = useCallback(() => {
    setSlideIndex(incomingSlideIndex);
    setIncomingSlideIndex(-1);
    setEnableAnimation(false);
  }, [incomingSlideIndex]);

  const handleScrollToSection = useCallback(
    (sectionIndex: number) => {
      const selectedIndex = slides.length - 1 - sectionIndex;
      const delta = slideIndex - selectedIndex;
      if (delta > 0) {
        onScrollDown(selectedIndex);
      } else {
        onScrollUp(selectedIndex);
      }
    },
    [onScrollDown, onScrollUp, slideIndex, slides.length]
  );

  const handleMouseDown = useCallback((evt: React.MouseEvent<HTMLDivElement>) => {
    setIsMouseDown(true);
    mouseDownStartY.current = evt.clientY;
  }, []);

  const handleMouseMove = useCallback(
    (evt: React.MouseEvent<HTMLDivElement>) => {
      evt.preventDefault();
      if (!isMouseDown || scrollPosition !== 0) {
        return;
      }
      if (evt.clientY - mouseDownStartY.current > MIN_MOUSE_SWIPE_DISTANCE) {
        onScrollUp(slideIndex + 1);
        setIsMouseDown(false);
      } else if (mouseDownStartY.current - evt.clientY > MIN_MOUSE_SWIPE_DISTANCE) {
        if (slideIndex === 0) {
          scrollToSection(null, anchorRef.current, -headerHeight);
        } else {
          onScrollDown(slideIndex - 1);
        }
        setIsMouseDown(false);
      }
    },
    [headerHeight, isMouseDown, onScrollDown, onScrollUp, scrollPosition, slideIndex]
  );

  const handleMouseUp = useCallback(() => {
    setIsMouseDown(false);
  }, []);

  useEffect(() => {
    const setPosition = () => {
      setScrollPosition(window.scrollY);
    };
    window.addEventListener('scroll', setPosition);
    return () => {
      window.removeEventListener('scroll', setPosition);
    };
  }, []);

  useEffect(() => {
    anchorRef.current = document.getElementById('anchorScroll');
  }, []);

  return (
    <HeroContainer
      onMouseDown={handleMouseDown}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onTouchStart={onTouchStart}
      onTouchMove={onTouchMove}
      onTouchEnd={() => checkAnimation(onTouchEnd)}
      onWheel={delayOnWheel}
      ref={heroRef}
      headerHeight={headerHeight}
      viewportHeight={viewportHeight}
      isMouseDown={isMouseDown}
      scrollPosition={scrollPosition}
      enableAnimation={enableAnimation}
    >
      {scrollPosition === 0 && (slideIndex !== 0 || enableAnimation) && <OverScrollDisabled />}
      {slides.map((slide, index) => (
        <SliderWrapper
          key={slide.id}
          headerHeight={headerHeight}
          viewportHeight={viewportHeight}
          className={`${selectAnimationMode(index)} ${index === slideIndex ? 'show' : ''}`}
          onAnimationEnd={onAnimationEndAction}
        >
          <SlideHome
            slide={slide}
            indexSlide={index}
            onTrack={onTrack}
            showSlide={index === slideIndex}
            viewportHeight={viewportHeight}
            headerHeight={headerHeight}
          />
          <ScrollButtonContainer>
            <ButtonScroll>
              <ButtonSlide
                smallSize={50}
                label="Go to next slide"
                onClick={() => {
                  onScrollDown(slideIndex - 1);
                  if (slideIndex === 0) {
                    scrollToSection(null, anchorRef.current, -headerHeight);
                  }
                }}
                small
                animated
                rotation={90}
                buttonColor="#fff"
                hoverColor="rgba(255, 255, 255, 0.8)"
                clickColor="rgba(255, 255, 255, 0.6)"
                name="action_goNext"
              />
            </ButtonScroll>
          </ScrollButtonContainer>
          <ScrollStickContainer>
            <SectionStick
              onGoToSlide={scrollPosition === 0 ? handleScrollToSection : null}
              currentSlide={slides.length - 1 - slideIndex}
              slides={slides}
            />
          </ScrollStickContainer>
        </SliderWrapper>
      ))}
    </HeroContainer>
  );
};
export default HeroSliderAnimation;
