import { useEffect, useRef } from 'react';
import { useIntersectionObserver } from '@vfit/shared/hooks';
import { ISnapScrollProps } from './snapScroll.models';
import SlideHome from '../SlideHome/slideHome';
import { SliderWrapper } from './snapScroll.style';

const SnapScroll = ({
  slide,
  indexSlide,
  zIndex,
  setRef,
  onChangeVisibility,
  onTrack,
  headerHeight,
  viewportHeight,
}: ISnapScrollProps) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(ref, {
    rootMargin: '-1%',
    threshold: 0.7,
  });
  const isVisible = !!entry?.isIntersecting;

  useEffect(() => {
    setRef(ref);
  }, [ref, setRef]);

  useEffect(() => {
    if (isVisible && onChangeVisibility) {
      onTrack?.(slide);
      onChangeVisibility({
        index: indexSlide,
        ref: ref.current,
      });
    }
  }, [indexSlide, isVisible, onChangeVisibility]);

  return (
    <SliderWrapper
      ref={ref}
      viewportHeight={viewportHeight}
      headerHeight={headerHeight}
      indexSlide={indexSlide}
      zIndex={zIndex}
    >
      <SlideHome slide={slide} indexSlide={indexSlide} headerHeight={headerHeight} />
    </SliderWrapper>
  );
};

export default SnapScroll;
