import { ImageDivider, CustomText } from '@vfit/shared/atoms';
import { Fade } from 'react-awesome-reveal';
import { IVideo } from './video.models';
import { VideoContainer, DisclaimerContainer, Title } from './video.style';

const Video = ({ title, url, divider, disclaimer }: IVideo) => (
  <>
    {title && (
      <Title>
        <CustomText
          text={title}
          size={36}
          lineHeight={50}
          sizeMobile={30}
          lineHeightMobile={38}
          margin={[0, 0, 0, 0]}
        />
      </Title>
    )}
    <VideoContainer
      style={{
        marginTop: title ? 0 : 45,
      }}
    >
      {url && (
        <iframe
          src={url}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title={' '}
        />
      )}
    </VideoContainer>
    {disclaimer && (
      <DisclaimerContainer>
        <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
      </DisclaimerContainer>
    )}
    {divider?.image && (
      <Fade direction="up" triggerOnce>
        <ImageDivider image={divider?.image} alt={divider.text || ''} />
      </Fade>
    )}
  </>
);

export default Video;

