import styled from 'styled-components';
import { fonts } from '@vfit/shared/themes';
import { PriceVariantForStyled, IAmount } from '@vfit/shared/models';
import { getVariant } from '../../price.style';

export const SpanWrapper = styled.span<Pick<IAmount, 'isBlock'>>`
  ${({ isBlock }) => (isBlock ? 'display: block;' : '')}
  font-family: ${fonts.regular};
  font-weight: 400;
  line-height: 1;
`;

export const OldAmount = styled.span<PriceVariantForStyled>`
  ${({ variant }) => getVariant('oldAmount', variant)}
`;

export const Amount = styled.span<PriceVariantForStyled>`
  ${({ variant }) => getVariant('amount', variant)}
`;

export const Recurrence = styled.span<PriceVariantForStyled>`
  ${({ variant }) => getVariant('recurrence', variant)}
`;

export const FreeAmountRecurrence = styled.span<PriceVariantForStyled>`
  ${({ variant }) => getVariant('freeAmountRecurrence', variant)}
`;