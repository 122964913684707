import styled from "styled-components";
import { breakpoints, pxToCssFont, pxToRem, fonts } from '@vfit/shared/themes';


export const MapContainer = styled.div`
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: -1;
`;

export const CardPriceLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 327px;
  height: auto;
  border-radius: 20px;
  border: 1px solid rgba(190, 190, 190, 1);
  background-color: #ffffff;
  padding: 24px 24px 24px 24px;
  gap: 24px;
  p{
    margin:0;
  }
  @media(min-width: ${breakpoints.tablet}){
    margin-top: 180px;
    padding: 24px;
  }
  @media(min-width: ${breakpoints.desktop}){
    min-width: 405px;
    border-radius: 30px;
    margin: 0;
    padding: 32px 36px 32px 36px;
    gap: 32px;
  }
`;


export const CardAddressLayout = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  gap: 8px;
  @media(max-width: ${breakpoints.tablet}){
    margin-bottom: 120px;
  }
  @media(min-width: ${breakpoints.tablet}){
    padding: 0;
    width: 328px;
  }
  @media(min-width: ${breakpoints.desktop}){
    width: 514px;
  }

`;

export const Title = styled.div`
  ${pxToCssFont(32, 40)};
  font-family: ${fonts.exbold};

  @media(min-width: ${breakpoints.desktop}){
    ${pxToCssFont(44, 52)};

  }

`;

export const Description = styled.div`
  font-weight: 400;
  ${pxToCssFont(20, 28)};
  font-family: ${fonts.regular};
  p{
    margin: 0;
  }
  @media(min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(22, 32)};
  }
`;

export const Specs = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 12px;
  list-style: none;
  li{
    display:flex;
    gap: 12px;
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(20, 28)};
    @media(min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)};
  }

  }
`;

export const StickyButton = styled.div`
  width: 100vw;
  height: ${pxToRem(77)};
  display: flex;
  justify-content: space-around;
  align-items: center;
  background:  linear-gradient(0deg, rgba(255, 255, 255, 0.85), rgba(255, 255, 255, 0.85));
  border-top: 0.5px solid #BEBEBE;
  border-bottom: 0.5px solid #BEBEBE;
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  position:fixed;
  right:0;
  bottom: 0;
  z-index:3
`;

export const FooterPrice = styled.div`
  font-family: ${fonts.exbold};
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0;
  text-align: left;
`;

export const CheckMarkContainer = styled.div`
  width: ${pxToRem(18)};
  height: ${pxToRem(26)};
`;
