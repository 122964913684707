const getMaxHeight = (ref: HTMLDivElement) => {
  const allDocumentItems = ref.getElementsByClassName('document-item') || [];
  let allHeight = [];
  for (let i = 0; i < allDocumentItems.length; i++) {
    allHeight.push((allDocumentItems[i] as HTMLElement).clientHeight);
  }
  return Math.max(...allHeight);
};
export { getMaxHeight };

