import { Children, useState } from 'react';
import { ButtonSlide } from '@vfit/shared/atoms';
import { IPartyPrivacyItem } from '@vfit/shared/models';
import { trackLink } from '@vfit/shared/data-access';
import { Container, Title, Description, ButtonDiv } from './consentsCustomize.style';
import { IConsentCustomizeProps } from './consentsCustomize.models';
import ConsentsDetail from '../ConsentsDetails/consentsDetail';

const Consents = ({
  partyPrivacyItems,
  handleClose,
  title,
  description,
  buttonText,
}: IConsentCustomizeProps) => {
  const [localList, setLocalList] = useState<IPartyPrivacyItem[]>(partyPrivacyItems);

  const handleChangeConsent = (c: IPartyPrivacyItem, value: boolean) => {
    setLocalList(
      localList.map((consent: IPartyPrivacyItem) => {
        if (consent.id === c.id) {
          return { ...c, selectedValue: value };
        }
        return consent;
      })
    );
  };

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      {Children.toArray(
        partyPrivacyItems.map((c: IPartyPrivacyItem, index: number) => (
          <ConsentsDetail
            consent={c}
            onChangeConsent={handleChangeConsent}
            showDivider={partyPrivacyItems.length !== index + 1}
          />
        ))
      )}
      <ButtonDiv>
        <ButtonSlide
          label={buttonText}
          onClick={() => {
            // eslint-disable-next-line no-plusplus,no-param-reassign
            trackLink(localList.map((e, i) => `${++i}:${e.selectedValue ? 1 : 0}`).join(',') || '');
            handleClose(localList);
          }}
          buttonColor="#e60000"
          labelColor="#fff"
          hoverColor="#bd0000"
          clickColor="#a10000"
          name="action_customizeConsents"
        />
      </ButtonDiv>
    </Container>
  );
};

export default Consents;
