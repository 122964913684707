import { breakpoints, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';
import { IBannerCMS } from '@vfit/shared/models';

export const HTMLWrapper = styled.div``;

export const XTopClose = styled.button`
  border: none;
  cursor: pointer;
  padding: 0;
  position: absolute;
  right: ${pxToRem(24)};
  top: ${pxToRem(16)};
`;

export const BannerStyle = styled.section<{
  animationType?: IBannerCMS['animationType'];
  delay?: string;
}>`
  overflow: hidden;
  z-index: 1;
  opacity: ${(p) =>
    p.animationType === 'fade-in' || p.animationType === 'slide-left-to-right' ? 0 : 1};
  ${(p) => (p.animationType === 'slide-down' ? 'transition: translateY(-100%)' : '')};
  ${(p) => (p.animationType === 'slide-left-to-right' ? 'transform: translateX(-100%)' : '')};
  ${(p) =>
    p.animationType ? `animation: ${p.animationType} 0.5s ease-in forwards ${p.delay}s` : ''};
  bottom: ${pxToRem(23)};
  right: ${pxToRem(16)};
  position: fixed;
  top: unset;
  width: calc(100% - ${pxToRem(32)});

  @media (min-width: ${breakpoints.tablet}) {
    bottom: ${pxToRem(32)};
    right: ${pxToRem(32)};
    width: ${pxToRem(331)};
  }

  @media (min-width: ${breakpoints.desktop}) {
    bottom: ${pxToRem(80)};
    right: ${pxToRem(80)};
    width: ${pxToRem(516)};
  }

  @keyframes slide-left-to-right {
    from {
      transform: translateX(-100%);
      opacity: 0;
    }

    to {
      transform: translateX(0);
      opacity: 1;
    }
  }

  @keyframes slide-down {
    from {
      transform: translateY(-100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes slide-up {
    from {
      transform: translateY(100%);
    }

    to {
      transform: translateY(0);
    }
  }

  @keyframes fade-in {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }
`;
