import {CheckMarkContainer} from "../headerWithMap.style";

export const CheckMark = () => {
  const checkMarkSvg = <svg width="17" height="12" viewBox="0 0 17 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.64096 5.32276C1.26376 4.9513 0.657468 4.95359 0.282556 5.3285C-0.0917829 5.70284 -0.0946491 6.30912 0.276815 6.6869L5.30744 11.7175C5.4883 11.8984 5.73344 12 5.98951 12C6.24557 12 6.49073 11.8984 6.67157 11.7175L16.7327 1.65583C16.9175 1.47555 17.0226 1.22926 17.0243 0.970886C17.0261 0.713101 16.9244 0.465076 16.7419 0.2825C16.5593 0.0999234 16.3113 -0.00169817 16.0529 2.14747e-05C15.7951 0.00174391 15.5482 0.106811 15.3685 0.291686L5.98957 9.67022L1.64096 5.32276Z" fill="#262626"/>
  </svg>

  return (
    <CheckMarkContainer>
      {checkMarkSvg}
    </CheckMarkContainer>
  )
}
