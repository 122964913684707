import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const HomeLinksContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 64px;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
    column-gap: 26px;
    margin-left: 24px;
    margin-right: 24px;
    margin-bottom: 120px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 120px;
  }
`;

export const AnchorContainer = styled.div<{ isEven: boolean }>`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  flex: 100%;
  width: 100%;
  margin-bottom: 14px;

  @media (min-width: ${breakpoints.tablet}) {
    flex: ${(props) => (props.isEven ? '45%' : '100%')};
    margin-bottom: 14px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex: 1;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-self: flex-start;
    text-align: center;
    margin-bottom: 0;
  }
`;

export const AnchorText = styled.p`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$262626};
  min-height: 40px;
  ${pxToCssFont(18, 24)}
  margin-top: 0;
  margin-bottom: 0;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 auto;
    margin-top: 17px;
    max-width: 190px;
    min-height: 40px;
  }
`;

export const CustomDesc = styled.p`
  font-family: ${fonts.light};
  font-weight: 400;
  color: ${colors.$262626};
  min-height: 40px;
  ${pxToCssFont(24, 32)}
  margin-top: 0;
  margin-left: 24px;
  margin-right: 24px;
  margin-bottom: 24px;

  p {
    margin-top: 0;
    margin-bottom: 0;
  }
`;

export const AnchorTitle = styled.p`
  font-family: ${fonts.exbold};
  font-weight: 400;
  color: ${colors.$262626};
  margin-top: 6px;
  margin-bottom: 0;
  min-height: 40px;
  ${pxToCssFont(24, 30)}

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 50)}
  }
`;

export const AnchorTextWrapper = styled.p`
  margin-right: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-right: 0;
  }
`;

export const ArrowButton = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  margin-bottom: 10px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 0;
  }
`;
