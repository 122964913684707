import { VfModalStepper } from '@vfit/shared/components';
import { Children, forwardRef, memo, useEffect, useImperativeHandle, useState } from 'react';
import { IStepperModal } from '@vfit/shared/models';
import * as S from './stepperModal.style';
import { updateVisibility, DEFAULT_HEIGHT } from './stepperModal.utils';

const StepperModal = forwardRef(
  ({ id, components, isOpen, handleClose }: IStepperModal, stepperRef) => {
    const [step, setStep] = useState(0);

    useEffect(() => {
      if (isOpen) updateVisibility(step);
    }, [isOpen, step]);

    const onCloseModal = () => {
      handleClose();
      setStep(0);
    };

    const onNext = () => {
      setStep((prevState) => {
        if (prevState === components.length) return prevState;
        return prevState + 1;
      });
    };

    const onPrev = () => {
      setStep((prevState) => {
        if (prevState - 1 === -1) return prevState;
        return prevState - 1;
      });
    };

    useImperativeHandle(stepperRef, () => ({
      _stepperModalGoNext() {
        onNext();
      },
      _stepperModalGoPrev() {
        onPrev();
      },
    }));

    return (
      <VfModalStepper
        id={id}
        isOpen={isOpen}
        showBackCta={step !== 0}
        onBack={onPrev}
        handleClose={onCloseModal}
        height={DEFAULT_HEIGHT}
      >
        <>
          {Children.toArray(
            components?.map((component) => (
              <S.StepperChildren className="stepper-children">{component}</S.StepperChildren>
            ))
          )}
        </>
      </VfModalStepper>
    );
  }
);

const StepperModalMemo = memo(StepperModal, (prevProps, nextProps) => {
  return prevProps?.isOpen === nextProps?.isOpen;
});

export default StepperModalMemo;
