import { useEffect, useRef, useState } from 'react';
import { CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { fonts } from '@vfit/shared/themes';
import DocumentItem from './components/documentItem';
import { IDocumentsProps } from './documents.models';
import {
  CustomTextLabelWrapper,
  DocumentsContainer,
  DocumentsItemsWrapper,
} from './documents.style';
import { getMaxHeight } from './documents.utils';

const Documents = (props: IDocumentsProps) => {
  const { label, documentItems } = props;
  const { isTablet, isDesktop, isMobile } = useDeviceType();
  const [maxHeight, setMaxHeight] = useState<number>(0);
  const productListsElements = useRef<HTMLDivElement>(null);
  useEffect(() => {
    productListsElements.current && setMaxHeight(getMaxHeight(productListsElements.current));
  }, [isTablet, isDesktop, isMobile, productListsElements]);

  return (
    <DocumentsContainer ref={productListsElements}>
      {!isDesktop && (
        <CustomTextLabelWrapper>
          <CustomText
            text={label}
            textAlign="left"
            lineHeight={isTablet ? 38 : 45}
            size={isTablet ? 30 : 36}
            lineHeightMobile={38}
            sizeMobile={30}
            fontFamily={fonts.light}
          />
        </CustomTextLabelWrapper>
      )}
      <DocumentsItemsWrapper>
        {isDesktop && (
          <CustomTextLabelWrapper>
            <CustomText
              text={label}
              textAlign="left"
              lineHeight={isTablet ? 38 : 45}
              size={isTablet ? 30 : 36}
              lineHeightMobile={38}
              sizeMobile={30}
              fontFamily={fonts.light}
            />
          </CustomTextLabelWrapper>
        )}

        <>
          {documentItems?.map(({ title, buttonAction }) => (
            <DocumentItem height={maxHeight} title={title} buttonAction={buttonAction} />
          ))}
          {documentItems && isTablet && documentItems?.length && documentItems.length % 2 !== 0 && (
            <DocumentItem visibility={'hidden'} />
          )}
        </>
      </DocumentsItemsWrapper>
    </DocumentsContainer>
  );
};
export default Documents;

