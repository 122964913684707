import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const SectionStickContainer = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;

  @media (min-width: ${breakpoints.tablet}) {
    row-gap: 10px;
  }
`;

export const Stick = styled.div<{ active: boolean; disabledPointer: boolean }>`
  width: 8px;
  cursor: ${({ active, disabledPointer }) => (active || !disabledPointer ? 'inherit' : 'pointer')};
  height: ${({ active }) => (active ? '32px' : '8px')};
  border: ${({ active }) => (active ? 'none' : `1px solid ${colors.$ffffff}`)};
  border-radius: ${({ active }) => (active ? '9px' : '20px')};
  background-color: ${({ active }) => (active ? colors.$ffffff : 'inherit')};
  transition: ${({ active }) =>
    active ? 'all 0.5s ease-in-out' : 'opacity .5s, background-color .5s, width .5s'};
  transition-delay: ${({ active }) => (active ? '0s' : '.5s, .5s, 0s')};

  @media (min-width: ${breakpoints.tablet}) {
    width: 10px;
    height: ${({ active }) => (active ? '40px' : '10px')};
  }
`;

