import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToRem,
} from '@vfit/shared/themes';

export const FooterContainer = styled.footer`
  margin-top: 63px;

  @media (min-width: ${breakpoints.desktop}) {
    margin-top: 0;
  }
`;

export const FooterTopContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  background: linear-gradient(45deg, ${colors.$820000} 0%, ${colors.$e60000} 100%);
  border-radius: 30px 30px 0 0;
  justify-content: space-between;
  align-items: center;
  padding: 36px 24px;

  p {
    font-family: ${fonts.regular};
    ${pxToCssFont(20, 28)}
    font-weight: 400;
    color: ${colors.$ffffff};
    margin: 0;
    margin-bottom: 24px;
    text-align: center;
  }

  @media (min-width: ${breakpoints.tablet}) {
    p {
      ${pxToCssFont(20, 28)}
      width: 327px;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    flex-direction: row;
    padding: 60px;

    p {
      text-align: inherit;
      margin-bottom: 0;
      font-family: ${fonts.light};
      ${pxToCssFont(30, 38)}
      width: auto;
    }
  }
`;

export const FooterBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${colors.$0d0d0d};
  padding: 36px 24px;
  flex: 1;

  @media (min-width: ${breakpoints.desktop}) {
    padding: 40px 60px;
  }
`;

export const FooterBottomContent = styled.div`
  border-bottom: 1px solid ${colors.$bebebe};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 26px;

  p {
    margin: 0;
    margin-bottom: 18px;
    font-family: ${fonts.regular};
    color: white;
    ${pxToCssFontSize(20)}
    font-weight: 700;
  }
`;

export const TopFooterBannerBottomContent = styled.div<{ color?: string }>`
  display: flex;
  flex-direction: column;
  min-height: 72px;
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 16px;
  padding-bottom: 16px;

  p {
    margin: 0;
    font-family: ${fonts.instrumentSansRegular};
    color: ${({ color }) => color || 'white'};
    ${pxToCssFontSize(16)}
    font-weight: 400;
  }
  a {
    color: ${({ color }) => color || 'white'};
    ${pxToCssFontSize(18)}
    font-weight: 400;
    font-family: ${fonts.light};
    text-decoration: none;
  }

  @media (min-width: ${breakpoints.tablet}) {
    p {
      width: 50px;
    }
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding-left: 60px;
    padding-right: 60px;
    p {
      width: 50px;
    }
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const FooterTopBannerLinks = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  padding-top: 0;
  margin-bottom: 16px;
  :first-child {
    margin-bottom: 32px;
  }
  a {
    font-weight: 700;
  }
  @media (min-width: ${breakpoints.tablet}) {
    :first-child {
      margin-bottom: 40px;
    }
    min-height: 24px;
  }
  @media (min-width: ${breakpoints.desktop}) {
    :first-child {
      margin-bottom: 0;
    }
    min-height: inherit;
    a {
      font-weight: 400;
    }
    flex-direction: row;
    align-items: center;
    margin-bottom: 0;
  }
  :last-child {
    margin-bottom: 0;
  }
`;

export const FooterLinks = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  padding-top: 24px;
`;

export const FooterLinksCol = styled.div`
  flex-grow: 1;
  width: 25%;

  p {
    margin: 0;
    margin-bottom: 18px;
    font-family: ${fonts.regular};
    color: white;
    ${pxToCssFontSize(20)}
    font-weight: 700;
  }

  div {
    display: flex;
    flex-direction: column;

    a {
      color: white;
      ${pxToCssFontSize(18)}
      font-weight: 400;
      font-family: ${fonts.regular};
      text-decoration: none;
      margin-bottom: 5px;
    }
  }
`;

export const FooterCopyright = styled.div`
  margin-top: 24px;
  text-align: center;

  span {
    color: ${colors.$bebebe};
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFontSize(18)}
  }
`;

export const FooterMobileLinks = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  flex: 1 1 65px;
  align-items: center;
`;

export const FooterMobileLink = styled.div<{ borderLeft: boolean }>`
  margin-top: 20px;
  padding-left: 10px;
  padding-right: 10px;
  row-gap: 10px;
  cursor: pointer;
  border-left: ${({ borderLeft }) => (borderLeft ? '1px solid #BEBEBE' : 'none')};

  span {
    color: ${colors.$bebebe};
    font-family: ${fonts.regular};
    font-style: normal;
    ${pxToCssFont(16, 22)}
    font-weight: 400;
  }
`;

export const FooterButton = styled.button<{ marginRight?: number; imageWidth?: number }>`
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  margin-right: ${({ marginRight }) => (marginRight ? `${marginRight}px` : 0)};

  img.apps {
    width: 146px;
  }

  img {
    cursor: pointer;
  }

  @media (min-width: ${breakpoints.desktop}) {
    img.apps {
      width: ${({ imageWidth }) => pxToRem(imageWidth || 146)};
    }
  }

  // TOFix

  @media only screen and (max-width: 375px) and (min-width: 290px) {
    img.apps {
      width: 119px;
    }
  }

  @media only screen and (max-width: 289px) and (min-width: 0) {
    img.apps {
      width: 80px;
    }
  }
`;

export const FooterAppWrapper = styled.div<{ height?: number }>`
  @media (min-width: ${breakpoints.desktop}) {
    height: ${({ height }) => (height ? pxToRem(height) : 'inherit')};
  }
`;

export const FooterFirstPart = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LogoContainer = styled.div`
  margin-right: 23px;
  height: 40px;
  img {
    object-fit: contain !important;
  }
`;
