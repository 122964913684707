import { breakpoints, pxToRem } from '@vfit/shared/themes';
import styled from 'styled-components';
import { IMargins } from './Skeleton.models';

export const Wrapper = styled.div<IMargins>`
  margin: ${({ margins }) => (margins != undefined ? pxToRem(...margins) : '0')};

  @media (max-width: ${breakpoints.tablet}) {
    ${({ tabletMargins }) =>
      tabletMargins != undefined ? `margin: ${pxToRem(...tabletMargins)}` : null}
  }
`;

export const Container = styled.div<{ itemsDistance?: number }>`
  div {
    margin-bottom: ${({ itemsDistance }) =>
      itemsDistance != undefined ? `${itemsDistance}` : '35'}px;
  }
`;
