import { useCountDown } from '@vfit/consumer/hooks';

const HeaderCountdown = ({ date }: { date?: string }) => {
  if (!date) return null;
  const now = new Date();
  const limit = new Date(date);
  const diffInSec = Math.floor((limit.getTime() - now.getTime()) / 1000);
  const [day, hour, minute, second] = useCountDown(diffInSec);
  const dataDays = document.getElementById('data-days');
  const dataDaysText = document.getElementById('data-days-text');
  const dataHours = document.getElementById('data-hours');
  const dataHoursText = document.getElementById('data-hours-text');
  const dataMinutes = document.getElementById('data-minutes');
  const dataMinutesText = document.getElementById('data-minutes-text');
  const dataSeconds = document.getElementById('data-seconds');
  const dataSecondsText = document.getElementById('data-seconds-text');

  // region LABEL GIORNI/GIORNO MINUTI/MINUTO ORE/ORA SECONDO/SECONDI
  if (dataDaysText?.innerText === 'giorni' || dataDaysText?.innerText === 'giorno') {
    if (parseInt(day, 10) === 1) {
      dataDaysText.innerText = 'giorno';
    } else {
      dataDaysText.innerText = 'giorni';
    }
  }

  if (dataHoursText?.innerText === 'ore' || dataHoursText?.innerText === 'ora') {
    if (parseInt(hour, 10) === 1) {
      dataHoursText.innerText = 'ora';
    } else {
      dataHoursText.innerText = 'ore';
    }
  }

  if (dataMinutesText?.innerText === 'minuti' || dataMinutesText?.innerText === 'minuto') {
    if (parseInt(minute, 10) === 1) {
      dataMinutesText.innerText = 'minuto';
    } else {
      dataMinutesText.innerText = 'minuti';
    }
  }

  if (dataSecondsText?.innerText === 'secondi' || dataSecondsText?.innerText === 'secondo') {
    if (parseInt(second, 10) === 1) {
      dataSecondsText.innerText = 'secondo';
    } else {
      dataSecondsText.innerText = 'secondi';
    }
  }
  // endregion

  if (dataDays) dataDays.innerText = day;
  if (dataHours) dataHours.innerText = hour;
  if (dataMinutes) dataMinutes.innerText = minute;
  if (dataSeconds) dataSeconds.innerText = second;
  return null;
};

export default HeaderCountdown;
