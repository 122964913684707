import styled from 'styled-components';
import { breakpoints, fonts, pxToCssFont } from '@vfit/shared/themes';

export const ExperienceCard = styled.div`
  height: 350px;
  width: 269px;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  background-position: center;
  border-radius: 15px;
  transform: translateZ(0);
  margin-right: 24px;
  white-space: normal;
  position: relative;
  cursor: pointer;

  @media (min-width: ${breakpoints.bigDesktop}) {
    border-radius: 17px;
    overflow: hidden;
    height: 320px;
    width: 100%;
    max-width: 296px;
    display: flex;
    margin-right: 0;
    margin-bottom: 24px;
    flex-shrink: 0;

    &:first-child {
      margin-left: 0;
    }

    &:last-child {
      margin: 0;
    }
  }
`;

export const ExperienceCardArt = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 18px;
  z-index: 1;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;

  img {
    border-radius: 18px;
  }

  @media (min-width: ${breakpoints.bigDesktop}) {
    border-radius: 0;
    transform: translateZ(0) scale(1);
    transition: transform 0.9s ease-in-out;

    &:hover {
      transform: scale(1.5);
      transition: transform 0.9s ease-in-out;
    }
  }
`;

export const ExperienceCardTitle = styled.div`
  font-family: ${fonts.regular};
  font-style: normal;
  font-weight: 700;
  ${pxToCssFont(20, 26)}
  margin-bottom: 2px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    font-family: ${fonts.exbold};
    font-weight: 400;
    ${pxToCssFont(24, 30)}
    margin-bottom: 8px;
  }
`;

export const ExperienceCardDesc = styled.div`
  min-height: 44px;
`;

export const ExperienceCardFooter = styled.div`
  margin: auto 0 0;
  backdrop-filter: blur(40px);
  background-color: rgba(53, 53, 53, 40%);
  color: white;
  border-radius: 15px;
  font-style: normal;
  font-weight: 400;
  ${pxToCssFont(16, 22)}
  z-index: 2;
  min-height: 124px;
`;

export const ExperienceCardInternalFooter = styled.div`
  display: flex;
  padding: 24px;
  @media (min-width: ${breakpoints.desktop}) {
    padding: 20px;
  }
`;

export const ExperienceCardFooterImgContainer = styled.div<{ maskIconSize?: number }>`
  flex-shrink: 0;
  margin-top: 11px;
  margin-left: 16px;

  @media (min-width: ${breakpoints.bigDesktop}) {
    margin-top: 16px;
  }

  img {
    max-width: ${({ maskIconSize }) => maskIconSize}px;
    object-fit: contain;
    max-height: ${({ maskIconSize }) => maskIconSize}px;
  }
`;

