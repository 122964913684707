import GoogleMapReact from 'google-map-react';
import { useEffect, useState } from 'react';
import { useDeviceType } from '@vfit/shared/hooks';
import { LoggerInstance } from '@vfit/shared/data-access';
import { MapContainer } from './headerWithMap.style';
import { CardAddress } from './components/cardAddress';
import { CardPrice } from './components/cardPrice';
import { MAP_CONFIG, MAP_OPTIONS } from './headerWithMap.utils';
import { IHeaderWithMapProps } from './headerWithMap.models';

const HeaderWithMap = ({
  cmsData,
  address,
  offerDetails,
  ctaRedirectUrl,
  userType,
}: IHeaderWithMapProps) => {
  const { title, specs, description, ctaLabel } = cmsData;
  const [mapKey, setMapKey] = useState(0);
  const { isDesktop, isTablet, isMobile } = useDeviceType();
  const coords = {
    lat: address?.latitude || 0,
    lng: address?.longitude || 0,
  };

  const renderMarkers = (
    map: any,
    maps: any,
    lat: number,
    lng: number,
    idMarker: number,
    icon: any
  ) => {
    const marker = new maps.Marker({
      position: { lat, lng },
      icon,
      map,
      zIndex: 60,
    });
    LoggerInstance.debug('Header With Map', marker);
  };
  const myLocationSize = isDesktop
    ? { height: 77.14, width: 60.68 }
    : { height: 56.02, width: 46.56 };

  const autoPanMap = (currentMap: google.maps.Map) => {
    if (isTablet) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const xOffs = -currentMap?.getDiv()?.offsetWidth * 0.25;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const yOffs = currentMap?.getDiv()?.offsetHeight * 0.25;
      currentMap?.panBy(xOffs, yOffs);
    } else if (isMobile) {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const xOffs = 0;
      // eslint-disable-next-line no-unsafe-optional-chaining
      const yOffs = currentMap?.getDiv()?.offsetHeight * 0.15;
      currentMap?.panBy(xOffs, yOffs);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      // Force Re-Render
      if (!isMobile) setMapKey((prevKey) => prevKey + 1);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <MapContainer>
        <GoogleMapReact
          key={mapKey}
          bootstrapURLKeys={MAP_CONFIG}
          zoom={isDesktop ? 18 : 16}
          options={MAP_OPTIONS}
          center={coords}
          draggable
          yesIWantToUseGoogleMapApiInternals
          onGoogleApiLoaded={({ map, maps }) => {
            renderMarkers(map, maps, coords?.lat, coords?.lng, -1, {
              scaledSize: new google.maps.Size(myLocationSize.height, myLocationSize.width),
            });
            autoPanMap(map);
          }}
        />
      </MapContainer>
      <CardAddress specs={specs} title={title} description={description} isMobile={isMobile} />
      <CardPrice
        offerName={offerDetails?.offerName?.toUpperCase() || ''}
        recurrence={offerDetails?.recurrence || ''}
        price={decodeURIComponent(offerDetails?.price || '')}
        originalPrice={decodeURIComponent(offerDetails?.originalPrice || '')}
        ctaLabel={ctaLabel}
        offerDescription={decodeURIComponent(offerDetails?.offerDescription || '')}
        redirectUrl={ctaRedirectUrl || '#'}
        isMobile={isMobile}
        specs={specs}
        userType={userType || 'anonymous'}
        urlClickToCall={offerDetails?.urlClickToCall || '#'}
      />
    </>
  );
};

export default HeaderWithMap;

