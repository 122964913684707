import React, { useEffect, useRef, useState } from 'react';
import { Fade } from 'react-awesome-reveal';
import { useDeviceType, useIntersectionObserver } from '@vfit/shared/hooks';
import { ButtonSlide, ImageDivider, ImageAtoms, CustomText } from '@vfit/shared/atoms';
import Card from './Card/card';
import {
  ExperiencesWrapper,
  ExperiencesContainer,
  ExperiencesContainerLeft,
  ExperiencesContainerLeftText,
  ExperiencesContainerLeftTitle,
  ExperiencesContainerLeftParagraph,
  ExperiencesContainerRight,
  ExperiencesLayoutColumn,
  ExperienceContainerButton,
  Parallax,
  DivImgContainer,
  DisclaimerContainer,
} from './experiencesSlider.style';
import { IExperiencesProps } from './experiencesSlider.models';
import { useScrollDirectionExp } from './experiencesSlider.utils';

const ExperiencesSlider = ({
  experiences,
  divider,
  disclaimer,
  handleButtonClick,
  handleClickCard,
}: IExperiencesProps) => {
  const { isBigDesktop, isMobile, isDesktop } = useDeviceType();
  const [startTranslate, setStartTranslate] = useState<number>(0);
  const parallaxDivider = useRef<HTMLDivElement | null>(null);
  const entry = useIntersectionObserver(parallaxDivider, {
    rootMargin: '-10%',
  });
  const isVisible = !!entry?.isIntersecting && (isDesktop || isBigDesktop);
  const { translateY } = useScrollDirectionExp({ isVisible });
  const { image, title: expTitle, description, elements: slides, customAction } = experiences;

  const translateYStyle = isBigDesktop
    ? {
        transform: slides.length == 4 ? `translate(-365px,${startTranslate}px ` : `translate(-25px,${startTranslate}px`
      }
    : {};

  const desktopLayout = () => (
    <ExperiencesContainerRight style={translateYStyle} className={`layout-${slides.length}`}>
      <ExperiencesLayoutColumn>
        {slides[0] && <Card handleClickCard={handleClickCard} {...slides[0]} />}
        {/* card play */}
        {slides[1] && <Card handleClickCard={handleClickCard} {...slides[1]} />}
      </ExperiencesLayoutColumn>
      {/* card training */}
      <ExperiencesLayoutColumn>
        {slides[2] && <Card handleClickCard={handleClickCard} {...slides[2]} />}
        {/* card travel */}
        {slides[3] && <Card handleClickCard={handleClickCard} {...slides[3]} />}
      </ExperiencesLayoutColumn>
      {/* card relax */}
      <ExperiencesLayoutColumn>
        {slides[4] && <Card handleClickCard={handleClickCard} {...slides[4]} />}
        {slides[5] && <Card handleClickCard={handleClickCard} {...slides[5]} />}
      </ExperiencesLayoutColumn>
    </ExperiencesContainerRight>
  );

  const mobileLayout = () => (
    <>
      <ExperiencesContainerRight className={`layout--${slides.length}`}>
        {slides[0] && <Card handleClickCard={handleClickCard} {...slides[0]} />}
        {/* card play */}
        {slides[1] && <Card handleClickCard={handleClickCard} {...slides[1]} />}
        {slides[2] && <Card handleClickCard={handleClickCard} {...slides[2]} />}
      </ExperiencesContainerRight>
      <ExperiencesContainerRight className={`layout--${slides.length}`}>
        {slides[3] && <Card handleClickCard={handleClickCard} {...slides[3]} />}
        {/* card relax */}
        {slides[4] && <Card handleClickCard={handleClickCard} {...slides[4]} />}
        {slides[5] && <Card handleClickCard={handleClickCard} {...slides[5]} />}
      </ExperiencesContainerRight>
    </>
  );

  useEffect(() => {
    setStartTranslate(translateY);
  }, [translateY]);

  return (
    <Parallax ref={parallaxDivider}>
      <ExperiencesWrapper>
        <ExperiencesContainer>
          <ExperiencesContainerLeft>
            <ExperiencesContainerLeftText>
              <ExperiencesContainerLeftTitle>
                <CustomText text={expTitle} textAlign={isBigDesktop ? 'left' : 'center'} />
              </ExperiencesContainerLeftTitle>
              <ExperiencesContainerLeftParagraph>{description}</ExperiencesContainerLeftParagraph>
              {customAction?.title && (
                <ExperienceContainerButton>
                  <ButtonSlide
                    label={customAction?.title}
                    small={false}
                    smallSize={55}
                    buttonColor="#fff"
                    hoverColor="rgba(255, 255, 255, 0.8)"
                    clickColor="rgba(255, 255, 255, 0.6)"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (handleButtonClick) {
                        handleButtonClick();
                      }
                    }}
                    name="action_expSlider"
                  />
                </ExperienceContainerButton>
              )}
            </ExperiencesContainerLeftText>
          </ExperiencesContainerLeft>
          <DivImgContainer absolute>
            <ImageAtoms nameCard="ExperienceCard" image={image} />
          </DivImgContainer>
          {isBigDesktop ? desktopLayout() : mobileLayout()}
        </ExperiencesContainer>
        {disclaimer && (
          <DisclaimerContainer>
            <CustomText text={disclaimer} lineHeight={22} size={16} textAlign="left" />
          </DisclaimerContainer>
        )}
      </ExperiencesWrapper>
      {divider?.image && (
        <Fade direction="up" triggerOnce>
          <ImageDivider
            image={divider?.image}
            imageMobile={divider.imageMobile}
            isMobile={isMobile}
            alt={divider.text || ''}
          />
        </Fade>
      )}
    </Parallax>
  );
};

export default ExperiencesSlider;

