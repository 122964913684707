import { breakpoints, colors } from '@vfit/shared/themes';
import styled from 'styled-components';

export const CTCBoxContainer = styled.div`
  padding: 24px 32px;
  min-height: 337px;
  width: 327px;
  background: ${colors.$ffffff};
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  margin: 0 auto 64px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 0 80px 80px;
    padding: 32px 64px;
    min-height: 232px;
    width: auto;
  }
`;

export const CustomTextTitleWrapper = styled.div`
  div {
    margin: 0 0 16px;
  }
`;

export const CustomTextDescriptionWrapper = styled.div`
  div {
    margin: 0 0 32px;
  }
`;

export const ButtonWrapper = styled.div`
  width: auto;

  button {
    padding: 10px 24px;
    min-height: 45px;
    border-radius: 30px;

    @media (min-width: ${breakpoints.desktop}) {
      min-width: 189px;
      width: auto;
    }
  }
`;

