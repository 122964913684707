import styled from 'styled-components';
import {
  breakpoints,
  colors,
  fonts,
  pxToCssFont,
  pxToCssFontSize,
  pxToFontSize,
  pxToRem,
} from '@vfit/shared/themes';

export const SlideHomeContainer = styled.div<{
  headerHeight: number;
  viewportHeight: number;
}>`
  height: ${({ viewportHeight, headerHeight }) =>
    viewportHeight ? `${viewportHeight - headerHeight}px` : `calc(100vh - ${headerHeight}px)`};
  width: 100vw;
  position: relative;
`;

export const SlideHomeContainerVideo = styled.div<{
  headerHeight: number;
  viewportHeight: number;
}>`
  height: ${({ viewportHeight, headerHeight }) =>
    viewportHeight ? `${viewportHeight - headerHeight}px` : `calc(100vh - ${headerHeight}px)`};
  width: 100vw;

  div {
    position: absolute;
    width: 100%;
  }

  div::after {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-image: linear-gradient(
      90deg,
      rgba(62, 62, 62, 60%) 17.03%,
      rgba(0, 0, 0, 0%) 83.87%
    );
  }

  video {
    object-fit: cover;
    width: 100%;
    height: ${({ viewportHeight, headerHeight }) =>
      viewportHeight ? `${viewportHeight - headerHeight}px` : `calc(100vh - ${headerHeight}px)`};

    @media (min-width: ${breakpoints.desktop}) {
      object-fit: cover;
    }
  }
`;

export const SlideHomeContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
`;

export const SlideHomeText = styled.div`
  margin-top: 35px;

  h2 {
    font-family: ${fonts.regular};
    ${pxToCssFont(16, 24)}
    letter-spacing: ${pxToRem(0.5)};
    text-transform: uppercase;
    color: ${colors.$ffffff};
    margin: 0 30px;
  }

  h3 {
    font-family: ${fonts.exbold};
    font-weight: 400;
    ${pxToCssFont(30, 30)}
    font-size: clamp(${pxToFontSize(30)}, 3vw + ${pxToFontSize(22)}, ${pxToFontSize(42)});
    color: ${colors.$ffffff};
    margin: 21px 30px 0;
  }

  h4,
  ul {
    font-family: ${fonts.light};
    ${pxToCssFont(18, 24)}
    font-weight: 400;
    color: ${colors.$ffffff};
    margin: 30px 30px 40px;
    max-width: 80%;
  }

  div {
    button {
      margin-left: 30px;
    }
  }

  @media (min-width: ${breakpoints.tablet}) {
    margin-top: 64px;

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFontSize(54)}
      line-height: 1.25;
      font-size: clamp(${pxToFontSize(42)}, 2vw + ${pxToFontSize(30)}, ${pxToFontSize(64)});
      max-width: 95%;
    }

    h4 {
      ${pxToCssFont(20, 30)}
      max-width: 64%;
    }
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 0 80px;

    h2 {
      ${pxToCssFont(18, 24)}
    }

    h3 {
      ${pxToCssFont(64, 64)}
      font-size: clamp(${pxToFontSize(42)}, 2vw + ${pxToFontSize(30)}, ${pxToFontSize(70)});
      max-width: 80%;
    }

    h4 {
      ${pxToCssFont(24, 30)}
      max-width: 60%;
    }
  }
`;

export const SlideHomeButtonSlideContainer = styled.div`
  width: 324px;
  max-width: 400px;

  btn {
    z-index: 4;
    position: relative;
    width: 324px;
    max-width: 400px;
  }
`;

export const Specs = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  gap: 12px;
  list-style: none;
  li {
    display: flex;
    gap: 12px;
    font-family: ${fonts.regular};
    font-weight: 700;
    ${pxToCssFont(20, 28)};
    @media (min-width: ${breakpoints.tablet}) {
      ${pxToCssFont(20, 30)};
    }
  }
`;

export const CheckMarkContainer = styled.div`
  width: ${pxToRem(18)};
  height: ${pxToRem(26)};
  svg {
    color: white;
  }
`;
