import { IBannerCMS } from '@vfit/shared/models';
import { Colors } from '@vfit/shared/themes';
import { Svg } from '@vfit/shared/atoms';
import * as S from './floatingbanner.style';
import { useFloatingBanner } from './floatingbanner.hook';

const FloatingBanner = ({
  animationType,
  animationDelay,
  buttonCloseXColor,
  htmlMobile,
  htmlTablet,
  htmlDesktop,
}: IBannerCMS) => {
  const { htmlPurified, isBannerClosed, closeBanner } = useFloatingBanner({
    htmlMobile: htmlMobile || '',
    htmlTablet: htmlTablet || '',
    htmlDesktop: htmlDesktop || '',
  });
  return isBannerClosed ? null : (
    <S.BannerStyle animationType={animationType} delay={animationDelay}>
      <S.XTopClose onClick={closeBanner}>
        <Svg name="close" color={(buttonCloseXColor as Colors) || '#0d0d0d'} />
      </S.XTopClose>
      <S.HTMLWrapper dangerouslySetInnerHTML={{ __html: htmlPurified }} />
    </S.BannerStyle>
  );
};

export default FloatingBanner;
