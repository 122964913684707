import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  margin-bottom: 120px;
`;

export const MainContainer = styled.div`
  margin: 0 auto;
  background-color: ${colors.$ffffff};
  max-width: 327px;

  @media (min-width: ${breakpoints.tablet}) {
    max-width: 680px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    max-width: ${pxToRem(1062)};
  }
`;

export const CardContainer = styled.div`
  display: flex;
  border: 1px solid ${colors.$bebebe};
  border-radius: 20px;
  justify-content: space-between;
  flex-direction: column-reverse;

  @media (min-width: ${breakpoints.tablet}) {
    flex-direction: row;
  }
`;

export const DescriptionBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 32px 24px;

  @media (min-width: ${breakpoints.tablet}) {
    min-height: 307px;
    width: 296px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: 441px;
    width: 403px;
  }
`;

export const Title = styled.h4`
  margin: 0;
  font-family: ${fonts.exbold};
  font-weight: 400;
  ${pxToCssFont(24, 38)};
  overflow: auto;
  padding-bottom: ${pxToRem(24)};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(28, 45)}
  }
`;

export const Description = styled.p`
  margin: 0;
  font-family: ${fonts.regular};
  font-weight: 400;
  ${pxToCssFont(18, 24)}
  color: ${colors.$262626};

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const CtaContainer = styled.div`
  margin-top: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    display: block;
  }

  @media (min-width: ${breakpoints.desktop}) {
    display: inline-block;
  }
`;

export const Info = styled.div`
  margin-top: 44px;

  div {
    text-align: left;
    margin: 0;
    font-family: ${fonts.regular};
    font-weight: 400;
    ${pxToCssFont(14, 18)}
    color: #666;

    a {
      color: ${colors.$262626};
      font-weight: 700;
      ${pxToCssFont(16, 22)}
    }

    a:hover {
      color: ${colors.$e60000};
    }

    a:visited {
      color: ${colors.$262626};
    }

    a:active {
      color: ${colors.$262626};
    }
  }
`;

export const ContainerImage = styled.div<{ disableCoverImg: boolean }>`
  height: 150px;
  object-fit: ${({ disableCoverImg }) => (disableCoverImg ? 'contain' : 'cover')};

  @media (min-width: ${breakpoints.tablet}) {
    height: auto;
    width: 50%;
  }

  @media (min-width: ${breakpoints.desktop}) {
    min-height: 441px;
  }
`;

export const ImageElement = styled.img`
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;

  @media (min-width: ${breakpoints.tablet}) {
    margin-left: 0;
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    border-top-left-radius: 0;
  }
`;

