import { ButtonSlide, CustomText } from '@vfit/shared/atoms';
import { useDeviceType } from '@vfit/shared/hooks';
import { colors, fonts } from '@vfit/shared/themes';
import { ICTCBoxProps } from './ctcBox.models';
import * as S from './ctcBox.style';

const CTCBox = (props: ICTCBoxProps) => {
  const { title, description, buttonLabel, buttonAction } = props;
  const { isTablet } = useDeviceType();

  return (
    <S.CTCBoxContainer>
      <S.CustomTextTitleWrapper>
        <CustomText
          text={title}
          textAlign="left"
          lineHeight={isTablet ? 38 : 45}
          size={isTablet ? 30 : 36}
          lineHeightMobile={38}
          sizeMobile={30}
          fontFamily={fonts.exbold}
        />
      </S.CustomTextTitleWrapper>
      <S.CustomTextDescriptionWrapper>
        <CustomText
          text={description || ''}
          textAlign="left"
          lineHeight={isTablet ? 26 : 30}
          size={20}
          lineHeightMobile={26}
          sizeMobile={20}
          fontFamily={fonts.regular}
        />
      </S.CustomTextDescriptionWrapper>
      <S.ButtonWrapper>
        <ButtonSlide
          buttonColor={colors.$e60000}
          labelColor={colors.$ffffff}
          hoverColor={colors.$bd0000}
          clickColor={colors.$a10000}
          label={buttonLabel}
          onClick={() => buttonAction?.()}
          name="action_ctc"
        />
      </S.ButtonWrapper>
    </S.CTCBoxContainer>
  );
};

export default CTCBox;
