import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont } from '@vfit/shared/themes';

export const Container = styled.div`
  button {
    background: transparent;
    border: none;
    font-family: ${fonts.regular};
    font-weight: 700;
    text-decoration: underline;
    ${pxToCssFont(16, 22)}
    margin-top: 8px;

    @media (min-width: ${breakpoints.tablet}) {
      margin-top: 32px;
    }

    &,
    &:active,
    &:visited {
      color: ${colors.$262626};
    }
  }
`;

export const Title = styled.div`
  font-family: ${fonts.exbold};
  font-weight: 400;
  color: ${colors.$262626};
  ${pxToCssFont(20, 26)}
  margin-bottom: 16px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(24, 30)}
    margin-bottom: 20px;
  }
`;

export const Description = styled.div`
  font-family: ${fonts.regular};
  font-weight: 400;
  color: ${colors.$262626};
  ${pxToCssFont(16, 22)}
  margin-bottom: 24px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(18, 24)}
  }
`;

export const Divider = styled.div`
  height: 1px;
  background-color: ${colors.$bebebe};
  margin: 36px 0 32px;

  @media (min-width: ${breakpoints.desktop}) {
    margin: 45px 0 40px;
  }
`;

