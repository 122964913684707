import { breakpoints } from '@vfit/shared/themes';
import styled from 'styled-components';

export const SliderWrapper = styled.div<{
  zIndex: number;
  viewportHeight: number;
  headerHeight: number;
  indexSlide: number;
}>`
  position: relative;
  z-index: ${({ zIndex }) => zIndex};
  ${({ headerHeight, indexSlide }) =>
    indexSlide > 0
      ? `
    margin-top: -${headerHeight}px;
  `
      : ''};
  background-color: #4a4d4e;
  height: ${({ viewportHeight }) => (viewportHeight ? `${viewportHeight}px` : '100vh')};
  border-radius: 0 0 20px 20px;
  overflow: hidden;

  @media (min-width: ${breakpoints.tablet}) {
    border-radius: 0 0 30px 30px;
  }
`;

