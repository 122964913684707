import styled from 'styled-components';
import { breakpoints, colors, fonts, pxToCssFont, pxToRem } from '@vfit/shared/themes';

export const Container = styled.div`
  padding: 32px 20px 36px;

  @media (min-width: ${breakpoints.tablet}) {
    padding: 60px 40px 31px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    padding: 60px 55px 0;
  }

  height: 100%;
`;

export const Paragraph = styled.p`
  margin: 0;
  font-weight: 400;
  color: ${colors.$262626};
`;

export const Title = styled(Paragraph)`
  font-family: ${fonts.exbold};
  ${pxToCssFont(30, 38)}
  text-align: center;
  margin-bottom: ${pxToRem(16)};

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(42, 52)}
  }

  @media (min-width: ${breakpoints.desktop}) {
    ${pxToCssFont(36, 45)}
  }
`;

export const Description = styled(Paragraph)`
  font-family: ${fonts.regular};
  ${pxToCssFont(20, 26)}
  text-align: center;
  margin-bottom: 23px;

  @media (min-width: ${breakpoints.tablet}) {
    ${pxToCssFont(20, 30)}
    margin-bottom: 32px;
  }

  @media (min-width: ${breakpoints.desktop}) {
    margin-bottom: 40px;
  }
`;

export const Cta = styled.button`
  display: flex;
  font-family: ${fonts.regular};
  background-color: transparent;
  padding: 0;
  border: none;
  border-bottom: 1px solid ${colors.$262626};
  color: ${colors.$262626};
  font-weight: 700;
  ${pxToCssFont(16, 22)}

  &:hover {
    cursor: pointer;
  }
`;

export const DropDownContainer = styled.div`
  width: 100%;
  margin-bottom: 26px;

  @media (min-width: ${breakpoints.tablet}) {
    margin-bottom: 32px;
  }
`;

