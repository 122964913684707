import { useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { IUseCountdown } from './useCountdown.models';

function padZero(time: number): string {
  return `${time}`.length < 2 ? `0${time}` : `${time}`;
}

export function getHMSTime(timeDiff: number): [string, string, string, string] {
  if (timeDiff <= 0) {
    return ['00', '00', '00', '00'];
  }
  const day = Math.floor(timeDiff / 86400);
  const hour = Math.floor((timeDiff % 86400) / 3600);
  const minute = Math.floor((timeDiff % 3600) / 60);
  const second = timeDiff % 60;
  return [padZero(day), padZero(hour), padZero(minute), padZero(second)];
}

export const useCountDown: IUseCountdown = (
  time: number,
  format: (num: number) => [string, string, string, string] = getHMSTime,
  callback?: () => void
) => {
  const [remainTime, setRemainTime] = useState(time);
  const [delay, setDelay] = useState<number | null>(1000);

  useInterval(() => {
    if (remainTime <= 0) {
      setDelay(null);
      return;
    }
    setRemainTime(remainTime - 1);
  }, delay);

  useEffect(() => {
    if (time > 0 && remainTime <= 0) {
      callback && callback();
    }
  }, [callback, remainTime, time]);

  const [day, hour, minute, second] = format(remainTime);

  return [day, hour, minute, second] as const;
};
