import { useEffect } from 'react';
import { useRouter } from 'next/router';

export function useScrollTop() {
  const router = useRouter();

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'instant' as any,
      });
    }, 0);
  }, [router.pathname]);
}
