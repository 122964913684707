import React from "react";
import {SectionStickContainer, Stick} from './sectionStick.style';
import {ISectionStickProps} from "./sectionStick.models";

const SectionStick = ({currentSlide, onGoToSlide, slides}: ISectionStickProps) => (
  <SectionStickContainer>
    {
      slides.map((s, index) => (
        <Stick
          key={s.id}
          onClick={() => onGoToSlide?.(index)}
          disabledPointer={!!onGoToSlide}
          active={index === currentSlide}
        />
      ))
    }
  </SectionStickContainer>
)

export default SectionStick;
